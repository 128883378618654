import request from 'graphql-request';
import { GRAPHQL_ENDPOINT } from '../../pages/_app';

export const MedicationSpendDocument = `query MedicationSpendDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        medicationSpendDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
          metricValue
          rangeLower
          rangeUpper
          percentageChange
        }
    }
`;

export function useGetMedicationSpend(queryVariables = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationSpendDocument,
    queryVariables,
    headers
  );
}

const hospitalListQuery = `query HospitalOptions($healthSystemId: String!) {
  hospitalOption(healthSystemId:$healthSystemId) {
  label
  value
  }
  }
`;
export function getHospitalsList(queryVariables, headers = {}) {
  return request(GRAPHQL_ENDPOINT, hospitalListQuery, queryVariables, headers);
}

export const MedicationSpendPerPatientDocument = `
    query MedicationSpendPerPatientDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        medicationSpendPerPatientDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
          metricValue
          rangeLower
          rangeUpper
        }
    }
`;

export function getMedicationSpendPerPatient(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationSpendPerPatientDocument,
    queryVariables,
    headers
  );
}

export const NewPatientDocument = `
    query NewPatientDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        newPatientDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
            metricValue
            rangeLower
            rangeUpper
        }
    }
`;

export function getNewPatient(queryVariables, headers) {
  return request(GRAPHQL_ENDPOINT, NewPatientDocument, queryVariables, headers);
}

export const SavingRevenueOpportunityDocument = `
    query SavingRevenueOpportunityDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        savingRevenueOpportunityDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
            metricValue
            rangeLower
            rangeUpper
            newOpportunities
            savingPotential
            realized
        }
    }
`;

export function getSavingRevenueOpportunity(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    SavingRevenueOpportunityDocument,
    queryVariables,
    headers
  );
}

export const UtilizationTrendDocument = `
    query UtilizationTrendDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        utilizationTrendDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
            metricValue
            rangeLower
            rangeUpper
            savingPotential
        }
    }
`;

export function getUtilizationTrend(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    UtilizationTrendDocument,
    queryVariables,
    headers
  );
}

export const PhysicianTrendDocument = `
    query PhysicianTrendDashTabMetric($healthSystemId: String!, $hospitalId: String, $frequency: String!) {
        physicianTrendDashTabMetric(healthSystemId: $healthSystemId, hospitalId: $hospitalId, frequency: $frequency) {
            metricValue
            rangeLower
            rangeUpper
            closedCount
        }
    }
`;

export function getPhysicianTrend(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    PhysicianTrendDocument,
    queryVariables,
    headers
  );
}

export const HospitalLevelSummariesDocument = `
    query HospitalLevelSummaries($healthSystemId: String!, $frequency: String!) {
        hospitalLevelSummaries(healthSystemId: $healthSystemId, frequency: $frequency) {
            hospitalName
            hospitalId
            medStrategyOpptyData {
              metricValue
              rangeLower
              rangeUpper
            }
            medUtilData {
              metricValue
              rangeLower
              rangeUpper
            }
            phyMedSpendData {
              metricValue
              rangeLower
              rangeUpper
            }
            medSpendData {
                metricValue
                rangeLower
                rangeUpper
            }
            msppData {
                metricValue
                rangeLower
                rangeUpper
            }
            losdata {
                metricValue
                rangeLower
                rangeUpper
            }
            pcensusdata {
                metricValue
                rangeLower
                rangeUpper
            }
        }
    }
`;

export function getHospitalLevelSummaries(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    HospitalLevelSummariesDocument,
    queryVariables,
    headers
  );
}

export function homePageSearch(queryVariables = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getFilterPagesQuery,
    queryVariables,
    headers
  );
}

export const getFilterPagesQuery = `query HomepageSearch($healthSystemId: String!, $queryString: String!){
    homepageSearch(
      queryString: $queryString
      healthSystemId: $healthSystemId
    ) {
      hospitalSearchResults {
        id
        name
      }
      drugSearchResults {
        id
        brandName
        genericName
        route
        drugForm
      }
      physicianSearchResults {
        id
        name
      }
      theraputicSearchResults {
        id
        normalizedDrg
        description
        drgCode
        drgCodeType
      }
    }
  }`;

export function homePageHospitalSearch(queryVariables = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getHospitalSearchQuery,
    queryVariables,
    headers
  );
}

export const getHospitalSearchQuery = `query HomepageSearch($healthSystemId: String!, $queryString: String!){
      homepageSearch(
        queryString: $queryString
        healthSystemId: $healthSystemId
      ) {
        hospitalSearchResults {
          id
          name
        }
      }
    }`;

export function homePagePhysicianSearch(queryVariables = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getPhysicianSearchQuery,
    queryVariables,
    headers
  );
}

export const getPhysicianSearchQuery = `query HomepageSearch($healthSystemId: String!, $queryString: String!){
        homepageSearch(
          queryString: $queryString
          healthSystemId: $healthSystemId
        ) {
          physicianSearchResults {
            id
            name
          }
        }
      }`;

export const getAdmissionGraphDataQuery = `query NewAdmissionsDashBoardChart(
  $hospitalIds: [String]
  $frequency: String
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
) {
  newAdmissionsDashBoardChart(
    hospitalIds: $hospitalIds
    frequency: $frequency
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
  ) {
    startDate
    endDate
    chart {
      value
      date
      id
    }
  }
}
`;
export function getAdmissionGraphData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getAdmissionGraphDataQuery,
    queryVariables,
    headers
  );
}

export const getMedicationSpendGraphDataQuery = `query TopMedicationSpendDashBoardChart(
  $hospitalIds: [String]
  $frequency: String
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
) {
  topMedicationSpendDashBoardChart(
    hospitalIds: $hospitalIds
    frequency: $frequency
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
  ) {
    chart {
      date
      id
      name
      value
    }
    endDate
    startDate
  }
}
`;
export function getMedicationSpendGraphData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getMedicationSpendGraphDataQuery,
    queryVariables,
    headers
  );
}

export const getPatientVolumeGraphDataQuery = `query PatientLoadDashBoardChart(
  $hospitalIds: [String]
  $frequency: String
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
) {
  patientLoadDashBoardChart(
    hospitalIds: $hospitalIds
    frequency: $frequency
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
  ) {
    startDate
    endDate
    chart {
      name
      id
      value
      date
    }
  }
}
`;
export function getPatientVolumeGraphData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getPatientVolumeGraphDataQuery,
    queryVariables,
    headers
  );
}

export const getLengthOfStayGraphDataQuery = `query LengthOfStayDashBoardChart(
  $hospitalIds: [String]
  $frequency: String
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
) {
  lengthOfStayDashBoardChart(
    hospitalIds: $hospitalIds
    frequency: $frequency
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
  ) {
    chart {
      value
      date
      id
    }
  }
}
`;
export function gettLengthOfStayGraphData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getLengthOfStayGraphDataQuery,
    queryVariables,
    headers
  );
}

export const hospitalDashTopMedSpendQuery = `query hospitalDashTopMedSpend($hospitalId: String , $page: Int, $size: Int) {
  hospitalDashTopMedSpend(hospitalId:$hospitalId, page: $page, size: $size){
    medicationName
    data{
      date
      value
    }
  }
}`;

export function getHospitalDashTopMedSpend(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    hospitalDashTopMedSpendQuery,
    queryVariables,
    headers
  );
}

export const hospitalDashTopTherapeuticSpendQuery = `query HospitalDashTopTherapeuticSpend($hospitalId: String, $page: Int, $size: Int, $selectedMedications: [String]) {
  hospitalDashTopTherapeuticSpend(hospitalId: $hospitalId, page: $page, size: $size, selectedMedications: $selectedMedications) {
    therapeuticName
    longDescription
    normalizedDrg
    data {
      date
      value
    }
  }
}
`;

export function getHospitalDashTopTherapeuticSpend(
  queryVariables,
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    hospitalDashTopTherapeuticSpendQuery,
    queryVariables,
    headers
  );
}

export const hospitalDashPhysicianQuery = `query HospitalDashTopPhysicianTable(
  $hospitalId: String
  $viewAll: Boolean
  $page: Int
  $size: Int
) {
  hospitalDashTopPhysicianTable(
    hospitalId: $hospitalId
    viewAll: $viewAll
    page: $page
    size: $size
  ) {
    drugSpend
    lengthOfStay
    physicianName
    costPerPatient
    id
    monthlyPatients
  }
}`;

export function getHospitalDashPhysicians(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    hospitalDashPhysicianQuery,
    queryVariables,
    headers
  );
}

export const overViewHospitalTableQuery = `query hospitalData($healthSystemId: String!) {
  healthSystemDashHospitalTable(healthSystemId: $healthSystemId){
    id
    name
    medicationSpend
    lengthOfStay
    newPatients
    utilizationCount
    opportunitiesCount
    physiciansCount
    opportunitiesSavings
    utilizationSavings
  }
}`;

export function getHospitalDashTableData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    overViewHospitalTableQuery,
    queryVariables,
    headers
  );
}

export const TopMedicationSpendsViewAllQuery = `query HospitalDashTopMedSpend($hospitalId: String, $page: Int, $size: Int, $selectedMedications: [String]) {
  hospitalDashTopMedSpend(hospitalId: $hospitalId, page: $page, size: $size, selectedMedications: $selectedMedications) {
    medicationName
    metrics {
      name
      value
    }
    data {
      date
      value
    }
  }
}`;

export function getTopMedSpendViewAll(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TopMedicationSpendsViewAllQuery,
    queryVariables,
    headers
  );
}

export const TopTherepeuticsSpendsViewAllQuery = `query HospitalDashTopTherapeuticSpend($hospitalId: String, $size: Int, $page: Int, $selectedMedications: [String]) {
  hospitalDashTopTherapeuticSpend(hospitalId: $hospitalId, size: $size, page: $page, selectedMedications: $selectedMedications) {
    therapeuticName
    longDescription
    normalizedDrg
    data {
      date
      value
    }
    metrics {
      name
      value
    }
  }
}`;

export function getTopTherepeuticsSpendViewAll(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TopTherepeuticsSpendsViewAllQuery,
    queryVariables,
    headers
  );
}

export const TopPhysiciansViewAllQuery = `query HospitalDashTopPhysicianTable($hospitalId: String, $viewAll: Boolean, $page: Int, $size: Int) {
  hospitalDashTopPhysicianTable(hospitalId: $hospitalId, viewAll: $viewAll, page: $page, size: $size) {
    id
    monthlyPatients
    drugSpend
    costPerPatient
    physicianName
    lengthOfStay
    data {
      date
      value
    }
  }
}`;

export function getTopPhysiciansViewAll(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    TopPhysiciansViewAllQuery,
    queryVariables,
    headers
  );
}

export const healthSystemQuery = `query HealthSystemOption {
  healthSystemOption {
    label
    value
  }
}`;

export function getHealthSystem(queryVariables, headers = {}) {
  return request(GRAPHQL_ENDPOINT, healthSystemQuery, queryVariables, headers);
}
