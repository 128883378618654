import request from 'graphql-request';
import { GRAPHQL_ENDPOINT } from '../../pages/_app';

const updatePoliciesQuery = `mutation UpdatePolicyStatusForUser($type: PolicyTypePayload) {
    updatePolicyStatusForUser(type: $type) {
      success
    }
  }`;

export function updatePrivacyPolicies(variables, headers = {}) {
  return request(GRAPHQL_ENDPOINT, updatePoliciesQuery, variables, headers);
}
