import {
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  parse,
  format,
  sub,
  add,
  differenceInDays,
  differenceInYears,
  differenceInMonths,
  differenceInWeeks
} from 'date-fns';
import { UTCDate } from "@date-fns/utc";

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// var localizedFormat = require('dayjs/plugin/localizedFormat')
// dayjs.extend(localizedFormat)
var updateLocale = require('dayjs/plugin/updateLocale');
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
});

dayjs.Ls.en.weekStart = 1;

export const getDateRange = ({
  frequency = '',
  startDate,
  endDate,
  count = 120,
  multipleOf = 20,
  local
}) => {
  switch (frequency) {
    case 'Weekly':
      let difference = differenceInWeeks(endDate, startDate);
      let calculatedCount =
        startDate && difference + multipleOf - (difference % multipleOf);
      return eachWeekOfInterval(
        {
          start: new UTCDate(sub(endDate, {
            weeks: calculatedCount ? calculatedCount - 1 : count - 1
          })),
          end: new UTCDate(endDate)
        },
        {
          weekStartsOn: 1
        }
      ).map(date =>
        local
          ? dayjs(date).format('YYYY-MM-DD')
          : dayjs(date).utc().format('YYYY-MM-DD')
      );
    case 'Monthly':
      difference = differenceInMonths(endDate, startDate);
      calculatedCount =
        startDate && difference + multipleOf - (difference % multipleOf);
      return eachMonthOfInterval({
        start: new UTCDate(sub(endDate, {
          months: calculatedCount ? calculatedCount - 1 : count - 1
        })),
        end: new UTCDate(endDate)
      }).map(date =>
        local
          ? dayjs(date).format('YYYY-MM-DD')
          : dayjs(date).utc().format('YYYY-MM-DD')
      );
    case 'yearly':
      difference = differenceInYears(endDate, startDate);
      calculatedCount =
        startDate && difference + multipleOf - (difference % multipleOf);
      return eachYearOfInterval({
        start: sub(endDate, {
          years: calculatedCount ? calculatedCount - 1 : count - 1
        }),
        end: endDate
      }).map(date => dayjs(date).utc().format('YYYY-MM-DD'));
    default:
      difference = differenceInDays(endDate, startDate);
      calculatedCount =
        startDate && difference + multipleOf - (difference % multipleOf);
      return eachDayOfInterval({
        start: sub(endDate, {
          days: calculatedCount ? calculatedCount - 1 : count - 1
        }),
        end: endDate
      }).map(date =>
        local
          ? dayjs(date).format('YYYY-MM-DD')
          : dayjs(date).utc().format('YYYY-MM-DD')
      );
  }
};

export const getFormattedDate = (d) => {
  const date = new Date(d || null);
  return dayjs(date).utc().format('YYYY-MM-DD');
};

export const getFormattedPreviousDate = d => {
  const date = new Date(d || null);
  return dayjs(sub(date, { days: 1 })).utc().format('YYYY-MM-DD');
};

export const getDayMonth = date => {
  return dayjs(date).format('D MMM');
};

export const getDayMonthYear = date => {
  return dayjs(date).format('D MMM YYYY');
};

export const getMonthYear = date => {
  return date && format(new Date(date), 'MMM yyyy');
};

export const getMonthShortYear = date => {
  return date && format(new Date(date), 'MMM yy');
};

export const showFormattedDateToShowInUI = dateRange => {
  const startDate = dateRange && dateRange[0]?.startDate;
  const endDate = dateRange && dateRange[0]?.endDate;

  const formattedStartDate = startDate
    ? dayjs(startDate).format('MMM. DD')
    : '';
  const formattedEndDate = endDate ? dayjs(endDate).format('MMM. DD') : '';

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate
  };
};

export const showFormattedDateToShowInUIComparePageTitle = dateRange => {
  if (dateRange && dateRange.length > 0) {
    const startDate = dayjs(dateRange[0].startDate).format('MMM DD, YYYY');
    const endDate = dayjs(dateRange[0].endDate).format(' MMM DD, YYYY');

    return {
      startDate,
      endDate
    };
  }

  return {
    startDate: '',
    endDate: ''
  };
};

export const showFormattedDateToShowInUIComparePageTitleViewAll = dateRange => {
  const startDateSplitted =
    dateRange && new Date(dateRange[0]?.startDate)?.toString()?.split(' ');

  const endDateSplitted =
    dateRange && new Date(dateRange[0]?.endDate)?.toString()?.split(' ');

  return {
    startDate:
      (startDateSplitted?.length > 2 &&
        `${startDateSplitted[1]} ${startDateSplitted[2]}, ${startDateSplitted[3]} `) ||
      '',
    endDate:
      (endDateSplitted?.length > 2 &&
        ` ${endDateSplitted[1]} ${endDateSplitted[2]}, ${endDateSplitted[3]}`) ||
      ''
  };
};

export const checkExpired = (date, days) => {
  const difference = dayjs().diff(dayjs(date), 'day');
  if (difference > days) {
    return true;
  } else {
    return false;
  }
};

export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const getDateOrRange = frequency => {
  if (frequency === 'Weekly') {
    const start = dayjs().startOf('week').format('MM-DD-YYYY');
    // const end = dayjs().subtract(1, 'day').format("MM-DD-YYYY")
    return `Week of Monday ${start}`;
  } else if (frequency === 'Monthly') {
    const startDate = dayjs().startOf('month');
    const start = new Date(startDate).toLocaleString('en-us', {
      month: 'long',
      year: 'numeric'
    });
    return `${start}`;
  } else if (frequency === 'Yearly') {
    const start = dayjs().startOf('year').format('YYYY');
    return `${start}`;
  } else {
    const yesterday = dayjs().subtract(1, 'day');
    return yesterday.format('MM-DD-YYYY');
  }
};

export function isValidDateFormat(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}

export function getFormattedDateAnalysisDateColumn(timestamp) {
  const validatedTimeStamp = parseFloat(timestamp)
    ? parseFloat(timestamp)
    : timestamp;
  const date = dayjs(validatedTimeStamp).utc();

  // "day Month, Year" format (e.g., "Monday September 12, 2023")
  const dayMonthYearFormat = date.format('D MMM, YYYY');

  // "Month Year" format (e.g., "September 2023")
  const monthYearFormat = date.format('MMM YYYY');

  // "Year" format (e.g., "2023")
  const yearFormat = date.format('YYYY');

  return {
    Daily: dayMonthYearFormat,
    Monthly: monthYearFormat,
    Yearly: yearFormat
  };
}
