import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Sidebar from './Sidebar';
import {
  faArrowTrendUp,
  faGauge,
  faMagnifyingGlassChart,
  faPills,
  faUserDoctor
} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { showAnalysis, showMedication, showPhysician, showUtilization } from '../../../store/constant';
export default function SwipeableTemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const navs = [
    { Icon: faGauge, navText: 'Overview', link: '/overview', visible: true },
    {
      Icon: faArrowTrendUp,
      navText: 'Utilization',
      link: '/utilization',
      visible: showUtilization
    },
    {
      Icon: faPills,
      navText: 'Medication Strategy',
      link: '/medication',
      visible: showMedication
    },
    {
      Icon: faUserDoctor,
      navText: 'Physician',
      link: '/physician',
      visible: showPhysician
    },
    {
      Icon: faMagnifyingGlassChart,
      navText: 'Analytics',
      link: '/analysis',
      visible: showAnalysis
    }
  ];

  const list = () => (
    <>
      <Box
        sx={{ width: 'auto' }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <Sidebar navs={navs} open={open} />
        </List>
      </Box>
    </>
  );

  return (
    <div>
      <React.Fragment key={'anchor'}>
        <IconButton onClick={toggleDrawer(true)}>
          <Menu />
        </IconButton>
        <SwipeableDrawer
          anchor={'left'}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
