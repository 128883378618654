import { atom } from 'recoil';

export const analysisState = atom({
  key: 'analysisState',
  default: { tabs: [] }
});

export const analysisStateFiltersState = atom({
  key: 'analysisStateFiltersState',
  default: { tabs: [] }
});

export const allColumnsSizingState = atom({
  key: 'allColumnsSizingState',
  default: {}
});

export const allColumnsSlidingRangeState = atom({
  key: 'allColumnsSlidingRangeState',
  default: {}
});

export const allColumnsFiltersState = atom({
  key: 'allColumnsFiltersState',
  default: {}
});

export const allGlobalFilterState = atom({
  key: 'allGlobalFilterState',
  default: {}
});

export const allColumnsSelectedRowsState = atom({
  key: 'allColumnsSelectedRowsState',
  default: {}
});

export const allColumnsAggregationState = atom({
  key: 'allColumnsAggregationState',
  default: {}
});

export const allColumnsSortingState = atom({
  key: 'allColumnsSortingState',
  default: []
});

export const analysisAttibutesListRecoilState = atom({
  key: 'analysisAttibutesListRecoilState',
  default: []
});

export const formattedAnalysisAttributesListState = atom({
  key: 'formattedAnalysisAttributesListState',
  default: []
});

export const formattedTableDataState = atom({
  key: 'formattedTableDataState',
  default: []
});

export const analysisSortingRecoilStateByDate = atom({
  key: 'analysisSortingRecoilStateByDate',
  default: []
});

export const analysisDateRecoilFormatState = atom({
  key: 'analysisDateFormatState',
  default: { dateFormatState: 'dayMonthYear' }
});

export const currentHealthSystemIdState = atom({
  key: 'currentHealthSystemIdState',
  default: null
});
