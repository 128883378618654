import { atom } from 'recoil';

export const commonToredirectFromOverview = atom({
  key: 'commonToredirectFromOverviewState',
  default: {}
});

export const historyRecoilState = atom({
  key: 'historyRecoilState',
  default: {}
});

export const userRecoilState = atom({
  key: 'userRecoilState',
  default: {}
});