export const InfoIcon = ({ width = 21, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.623 20C5.10005 20 0.623047 15.523 0.623047 10C0.623047 4.477 5.10005 0 10.623 0C16.146 0 20.623 4.477 20.623 10C20.623 15.523 16.146 20 10.623 20ZM10.623 18C12.7448 18 14.7796 17.1571 16.2799 15.6569C17.7802 14.1566 18.623 12.1217 18.623 10C18.623 7.87827 17.7802 5.84344 16.2799 4.34315C14.7796 2.84285 12.7448 2 10.623 2C8.50132 2 6.46648 2.84285 4.96619 4.34315C3.4659 5.84344 2.62305 7.87827 2.62305 10C2.62305 12.1217 3.4659 14.1566 4.96619 15.6569C6.46648 17.1571 8.50132 18 10.623 18ZM9.62305 5H11.623V7H9.62305V5ZM9.62305 9H11.623V15H9.62305V9Z"
        fill="#465671"
      />
    </svg>
  );
};

export const CurveDashedLine = ({ width }) => {
  return (
    <svg
      width={width || '118'}
      height="13"
      viewBox={`0 0 ${width || '118'} 13`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11.5L8.67157 3.82843C10.2337 2.26633 12.7663 2.26633 14.3284 3.82843L19.1716 8.67157C20.7337 10.2337 23.2663 10.2337 24.8284 8.67157L29.6716 3.82843C31.2337 2.26633 33.7663 2.26633 35.3284 3.82843L40.1716 8.67157C41.7337 10.2337 44.2663 10.2337 45.8284 8.67157L50.6716 3.82843C52.2337 2.26633 54.7663 2.26633 56.3284 3.82843L61.1716 8.67157C62.7337 10.2337 65.2663 10.2337 66.8284 8.67157L71.6716 3.82843C73.2337 2.26633 75.7663 2.26633 77.3284 3.82843L82.1716 8.67157C83.7337 10.2337 86.2663 10.2337 87.8284 8.67157L92.6716 3.82843C94.2337 2.26633 96.7663 2.26633 98.3284 3.82843L103.172 8.67157C104.734 10.2337 107.266 10.2337 108.828 8.67157L116.5 1"
        stroke="#FF5733"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
    </svg>
  );
};

export const DollarSign = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1218_22128)">
        <rect width="30" height="30" rx="4" fill="#387ED0" fill-opacity="0.1" />
        <path
          d="M15 22.5C10.8578 22.5 7.5 19.1423 7.5 15C7.5 10.8578 10.8578 7.5 15 7.5C19.1423 7.5 22.5 10.8578 22.5 15C22.5 19.1423 19.1423 22.5 15 22.5ZM15 21C16.5913 21 18.1174 20.3679 19.2426 19.2426C20.3679 18.1174 21 16.5913 21 15C21 13.4087 20.3679 11.8826 19.2426 10.7574C18.1174 9.63214 16.5913 9 15 9C13.4087 9 11.8826 9.63214 10.7574 10.7574C9.63214 11.8826 9 13.4087 9 15C9 16.5913 9.63214 18.1174 10.7574 19.2426C11.8826 20.3679 13.4087 21 15 21ZM12.375 16.5H16.5C16.5995 16.5 16.6948 16.4605 16.7652 16.3902C16.8355 16.3198 16.875 16.2245 16.875 16.125C16.875 16.0255 16.8355 15.9302 16.7652 15.8598C16.6948 15.7895 16.5995 15.75 16.5 15.75H13.5C13.0027 15.75 12.5258 15.5525 12.1742 15.2008C11.8225 14.8492 11.625 14.3723 11.625 13.875C11.625 13.3777 11.8225 12.9008 12.1742 12.5492C12.5258 12.1975 13.0027 12 13.5 12H14.25V10.5H15.75V12H17.625V13.5H13.5C13.4005 13.5 13.3052 13.5395 13.2348 13.6098C13.1645 13.6802 13.125 13.7755 13.125 13.875C13.125 13.9745 13.1645 14.0698 13.2348 14.1402C13.3052 14.2105 13.4005 14.25 13.5 14.25H16.5C16.9973 14.25 17.4742 14.4475 17.8258 14.7992C18.1775 15.1508 18.375 15.6277 18.375 16.125C18.375 16.6223 18.1775 17.0992 17.8258 17.4508C17.4742 17.8025 16.9973 18 16.5 18H15.75V19.5H14.25V18H12.375V16.5Z"
          fill="#387ED0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1218_22128">
          <rect width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const History = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1218_22119)">
        <rect width="30" height="30" rx="4" fill="#387ED0" fill-opacity="0.1" />
        <path
          d="M15 6C19.9707 6 24 10.0293 24 15C24 19.9707 19.9707 24 15 24C10.0293 24 6 19.9707 6 15H7.8C7.8 18.9762 11.0238 22.2 15 22.2C18.9762 22.2 22.2 18.9762 22.2 15C22.2 11.0238 18.9762 7.8 15 7.8C12.525 7.8 10.3416 9.0483 9.0465 10.95H11.4V12.75H6V7.35H7.8V9.6C9.4416 7.413 12.0561 6 15 6ZM15.9 10.5V14.6265L18.8187 17.5452L17.5452 18.8187L14.1 15.3717V10.5H15.9Z"
          fill="#387ED0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1218_22119">
          <rect width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Stethoscope = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1218_22122)">
        <rect width="30" height="30" rx="4" fill="#387ED0" fill-opacity="0.1" />
        <path
          d="M12 8.25V9.75H10.5V12.75C10.5 14.4075 11.8425 15.75 13.5 15.75C15.1575 15.75 16.5 14.4075 16.5 12.75V9.75H15V8.25H17.25C17.664 8.25 18 8.586 18 9V12.75C18 14.9798 16.3785 16.83 14.25 17.1877V18.375C14.25 19.8247 15.4253 21 16.875 21C17.9978 21 18.9562 20.295 19.3312 19.3027C18.546 18.9525 18 18.165 18 17.25C18 16.0072 19.0072 15 20.25 15C21.4928 15 22.5 16.0072 22.5 17.25C22.5 18.2782 21.81 19.1453 20.868 19.4137C20.4075 21.189 18.7943 22.5 16.875 22.5C14.5965 22.5 12.75 20.6535 12.75 18.375V17.1877C10.6215 16.8307 9 14.9798 9 12.75V9C9 8.586 9.336 8.25 9.75 8.25H12ZM20.25 16.5C19.836 16.5 19.5 16.836 19.5 17.25C19.5 17.664 19.836 18 20.25 18C20.664 18 21 17.664 21 17.25C21 16.836 20.664 16.5 20.25 16.5Z"
          fill="#387ED0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1218_22122">
          <rect width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StethoscopeGreen = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1238_29964)">
        <rect width="30" height="30" rx="4" fill="#57D57E" fill-opacity="0.1" />
        <path
          d="M12 8.25V9.75H10.5V12.75C10.5 14.4075 11.8425 15.75 13.5 15.75C15.1575 15.75 16.5 14.4075 16.5 12.75V9.75H15V8.25H17.25C17.664 8.25 18 8.586 18 9V12.75C18 14.9798 16.3785 16.83 14.25 17.1877V18.375C14.25 19.8247 15.4253 21 16.875 21C17.9978 21 18.9562 20.295 19.3312 19.3027C18.546 18.9525 18 18.165 18 17.25C18 16.0072 19.0072 15 20.25 15C21.4928 15 22.5 16.0072 22.5 17.25C22.5 18.2782 21.81 19.1453 20.868 19.4137C20.4075 21.189 18.7943 22.5 16.875 22.5C14.5965 22.5 12.75 20.6535 12.75 18.375V17.1877C10.6215 16.8307 9 14.9798 9 12.75V9C9 8.586 9.336 8.25 9.75 8.25H12ZM20.25 16.5C19.836 16.5 19.5 16.836 19.5 17.25C19.5 17.664 19.836 18 20.25 18C20.664 18 21 17.664 21 17.25C21 16.836 20.664 16.5 20.25 16.5Z"
          fill="#57D57E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1238_29964">
          <rect width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Refresh = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.09693 2.32447C4.41167 1.18766 6.12552 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 9.60208 14.9976 11.0869 14.1419 12.3055L11.75 8H14C14 4.68629 11.3137 2 8 2C6.38736 2 4.92333 2.6362 3.84517 3.67131L3.09693 2.32447ZM12.903 13.6755C11.5883 14.8123 9.87448 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 6.39789 1.00234 4.91312 1.8581 3.69458L4.25 8H2C2 11.3137 4.68629 14 8 14C9.61265 14 11.0767 13.3638 12.1549 12.3287L12.903 13.6755Z"
        fill="#465671"
      />
    </svg>
  );
};

export const Group = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1218_22125)">
        <rect width="30" height="30" rx="4" fill="#387ED0" fill-opacity="0.1" />
        <path
          d="M7.5 22.5C7.5 20.9087 8.13214 19.3826 9.25736 18.2574C10.3826 17.1321 11.9087 16.5 13.5 16.5C15.0913 16.5 16.6174 17.1321 17.7426 18.2574C18.8679 19.3826 19.5 20.9087 19.5 22.5H18C18 21.3065 17.5259 20.1619 16.682 19.318C15.8381 18.4741 14.6935 18 13.5 18C12.3065 18 11.1619 18.4741 10.318 19.318C9.47411 20.1619 9 21.3065 9 22.5H7.5ZM13.5 15.75C11.0138 15.75 9 13.7362 9 11.25C9 8.76375 11.0138 6.75 13.5 6.75C15.9862 6.75 18 8.76375 18 11.25C18 13.7362 15.9862 15.75 13.5 15.75ZM13.5 14.25C15.1575 14.25 16.5 12.9075 16.5 11.25C16.5 9.5925 15.1575 8.25 13.5 8.25C11.8425 8.25 10.5 9.5925 10.5 11.25C10.5 12.9075 11.8425 14.25 13.5 14.25ZM19.713 17.0273C20.767 17.5019 21.6615 18.2709 22.2889 19.2418C22.9164 20.2126 23.2501 21.344 23.25 22.5H21.75C21.7502 21.633 21.4999 20.7844 21.0293 20.0562C20.5587 19.328 19.8878 18.7512 19.0972 18.3953L19.7123 17.0273H19.713ZM19.197 8.55975C19.9526 8.87122 20.5987 9.40015 21.0533 10.0794C21.5078 10.7587 21.7503 11.5577 21.75 12.375C21.7503 13.4042 21.3658 14.3964 20.6719 15.1566C19.978 15.9168 19.025 16.3901 18 16.4835V14.9738C18.5557 14.8942 19.0713 14.6385 19.471 14.2443C19.8707 13.8502 20.1335 13.3382 20.2209 12.7837C20.3082 12.2292 20.2155 11.6612 19.9563 11.1633C19.6971 10.6653 19.2851 10.2636 18.7808 10.017L19.197 8.55975Z"
          fill="#387ED0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1218_22125">
          <rect width="30" height="30" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowUp = ({ width = 26 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={width}
        rx={width / 2}
        transform="matrix(1 0 0 -1 0 26)"
        fill="#387ED0"
      />
      <path
        d="M12.5293 11.0375L9.53182 14.4637C9.17807 14.8669 9.46557 15.5 10.0024 15.5H15.9974C16.1176 15.5001 16.2352 15.4656 16.3363 15.4005C16.4373 15.3355 16.5174 15.2427 16.5671 15.1333C16.6168 15.0239 16.6338 14.9025 16.6163 14.7836C16.5987 14.6648 16.5473 14.5535 16.4681 14.4631L13.4706 11.0381C13.4119 10.971 13.3396 10.9172 13.2584 10.8803C13.1772 10.8435 13.0891 10.8244 12.9999 10.8244C12.9108 10.8244 12.8227 10.8435 12.7415 10.8803C12.6603 10.9172 12.588 10.971 12.5293 11.0381V11.0375Z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowDown = ({ width = 26 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none">
      <rect width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
      <rect width="26" height="26" rx="13" fill="#387ED0" id="svg_1" />
      <path
        d="M12.351036227111816,15.421099090576172 L9.353536227111817,11.99484909057617 C8.999786227111816,11.591729090576173 9.287286227111817,10.958599090576172 9.824166227111817,10.958599090576172 H15.819156227111817 C15.939356227111816,10.958499090576172 16.05695622711182,10.993029090576172 16.157956227111818,11.058059090576172 C16.259056227111817,11.123099090576172 16.339156227111815,11.215869090576172 16.388856227111816,11.325279090576172 C16.438456227111814,11.434689090576171 16.455556227111817,11.556099090576172 16.43795622711182,11.674959090576172 C16.420456227111817,11.793819090576172 16.368956227111816,11.905099090576172 16.289756227111816,11.995479090576172 L13.292286227111816,15.420499090576172 C13.233626227111817,15.487599090576172 13.161276227111816,15.541399090576173 13.080106227111816,15.578299090576172 C12.998936227111816,15.615099090576171 12.910816227111816,15.634199090576171 12.821666227111816,15.634199090576171 C12.732516227111816,15.634199090576171 12.644396227111816,15.615099090576171 12.563216227111816,15.578299090576172 C12.482046227111816,15.541399090576173 12.409706227111817,15.487599090576172 12.351036227111816,15.420499090576172 V15.421099090576172 z"
        fill="white"
        id="svg_2"
        className=""
      />
    </svg>
  );
};

export const SmallArrowDown = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="9" fill="#387ED0" />
      <path
        d="M8.52938 10.9625L5.53188 7.53625C5.17813 7.13313 5.46563 6.5 6.00251 6.5H11.9975C12.1177 6.4999 12.2353 6.53443 12.3363 6.59946C12.4374 6.6645 12.5175 6.75727 12.5672 6.86668C12.6168 6.97609 12.6339 7.0975 12.6163 7.21636C12.5988 7.33522 12.5473 7.4465 12.4681 7.53688L9.47063 10.9619C9.41197 11.029 9.33962 11.0828 9.25845 11.1197C9.17728 11.1565 9.08916 11.1756 9.00001 11.1756C8.91086 11.1756 8.82274 11.1565 8.74156 11.1197C8.66039 11.0828 8.58805 11.029 8.52938 10.9619V10.9625Z"
        fill="white"
      />
    </svg>
  );
};

export const SmallArrowDownGraphs = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <rect
        id="backgroundrect"
        width="100%"
        height="100%"
        x="0"
        y="0"
        fill="none"
        stroke="none"
      />

      <rect
        width="20"
        height="20"
        rx="10"
        transform="matrix(1,0,0,-1,0,20) "
        fill="#387ED0"
        id="svg_1"
      />
      <path
        d="M9.529838560409546,7.875305032730102 L6.532338560409546,11.301505032730105 C6.178588560409546,11.704705032730104 6.4660885604095455,12.337805032730103 7.0029685604095455,12.337805032730103 H12.997918560409545 C13.118118560409545,12.337905032730102 13.235718560409545,12.303405032730105 13.336818560409547,12.238305032730104 C13.437818560409546,12.173305032730102 13.517918560409548,12.080505032730105 13.567618560409546,11.971105032730101 C13.617318560409544,11.8617050327301 13.634318560409547,11.740305032730102 13.616818560409548,11.621405032730102 C13.599218560409547,11.502605032730102 13.547818560409546,11.391305032730102 13.468618560409546,11.300905032730103 L10.471118560409543,7.875925032730102 C10.412418560409545,7.808795032730103 10.340118560409547,7.754995032730102 10.258918560409548,7.718135032730103 C10.177718560409545,7.681265032730103 10.089618560409548,7.6621950327301045 10.000468560409544,7.6621950327301045 C9.911308560409545,7.6621950327301045 9.823198560409544,7.681265032730103 9.742018560409548,7.718135032730103 C9.660848560409548,7.754995032730102 9.588498560409546,7.808795032730103 9.529838560409546,7.875925032730102 V7.875305032730102 z"
        fill="white"
        id="svg_2"
        className=""
        transform="rotate(180 10,10) "
      />
    </svg>
  );
};

export const Bulb = ({ width = '24' }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1170_20570)">
        <path
          d="M9.97296 18H14.027C14.159 16.798 14.772 15.806 15.767 14.723C15.88 14.601 16.599 13.856 16.684 13.75C17.3902 12.8676 17.8329 11.8037 17.9611 10.6807C18.0892 9.55781 17.8976 8.42151 17.4082 7.40271C16.9189 6.38392 16.1517 5.52406 15.1951 4.92215C14.2384 4.32025 13.1313 4.00078 12.001 4.00054C10.8708 4.0003 9.7635 4.31929 8.80662 4.92079C7.84974 5.52228 7.08221 6.38182 6.59242 7.4004C6.10263 8.41899 5.9105 9.55521 6.03815 10.6782C6.1658 11.8012 6.60805 12.8653 7.31396 13.748C7.39996 13.855 8.12096 14.601 8.23196 14.722C9.22796 15.806 9.84096 16.798 9.97296 18ZM14 20H9.99996V21H14V20ZM5.75396 15C4.81215 13.8233 4.22191 12.4044 4.05122 10.9069C3.88054 9.40944 4.13636 7.89418 4.78921 6.53572C5.44207 5.17725 6.46539 4.03085 7.74132 3.22856C9.01724 2.42627 10.4938 2.00074 12.001 2.00098C13.5082 2.00122 14.9847 2.42722 16.2604 3.22992C17.536 4.03262 18.559 5.17935 19.2114 6.53803C19.8638 7.8967 20.1192 9.41204 19.948 10.9095C19.7768 12.4069 19.1861 13.8256 18.244 15.002C17.624 15.774 16 17 16 18.5V21C16 21.5304 15.7892 22.0391 15.4142 22.4142C15.0391 22.7893 14.5304 23 14 23H9.99996C9.46953 23 8.96082 22.7893 8.58575 22.4142C8.21067 22.0391 7.99996 21.5304 7.99996 21V18.5C7.99996 17 6.37496 15.774 5.75396 15ZM13 10.004H15.5L11 16.004V12.004H8.49996L13 5.99997V10.005V10.004Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_1170_20570">
          <rect width={width} height={width} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Book = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1170_20541)">
        <path
          d="M19 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V15H22V19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22ZM18 17V19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V17H18ZM16 20V4H4V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H16ZM6 7H14V9H6V7ZM6 11H14V13H6V11ZM6 15H11V17H6V15Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_1170_20541">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SmallCurved = ({ width = 100 }) => {
  return (
    <svg
      width={width}
      height="8"
      viewBox="0 0 81 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.5L4.27884 3.94792C5.78039 2.32124 8.31763 2.22351 9.93986 3.72987L11.3703 5.05815C12.8665 6.44747 15.1689 6.48694 16.7118 5.14973L18.9632 3.19853C20.4303 1.92705 22.5986 1.8926 24.1053 3.11683L26.8026 5.30839C28.3497 6.56537 30.5861 6.49147 32.0468 5.13511L33.8703 3.44185C35.3665 2.05253 37.6689 2.01306 39.2118 3.35027L41.3803 5.22957C42.8837 6.53257 45.1163 6.53257 46.6197 5.22957L48.8803 3.27043C50.3837 1.96743 52.6163 1.96743 54.1197 3.27043L56.3803 5.22957C57.8837 6.53257 60.1163 6.53257 61.6197 5.22957L63.7882 3.35027C65.3311 2.01306 67.6335 2.05253 69.1297 3.44185L70.6754 4.87714C72.252 6.34115 74.7042 6.29575 76.2256 4.77439L80 1"
        stroke="#FF5733"
        strokesasharray="4 4"
      />
    </svg>
  );
};

export const ThreeDotsIcon = ({ width }) => {
  return (
    <svg
      width="3"
      height="18"
      viewBox="0 0 3 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0C0.675 0 0 0.675 0 1.5C0 2.325 0.675 3 1.5 3C2.325 3 3 2.325 3 1.5C3 0.675 2.325 0 1.5 0ZM1.5 15C0.675 15 0 15.675 0 16.5C0 17.325 0.675 18 1.5 18C2.325 18 3 17.325 3 16.5C3 15.675 2.325 15 1.5 15ZM1.5 7.5C0.675 7.5 0 8.175 0 9C0 9.825 0.675 10.5 1.5 10.5C2.325 10.5 3 9.825 3 9C3 8.175 2.325 7.5 1.5 7.5Z"
        fill="#848EA0"
      />
    </svg>
  );
};

export const PenIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.796 5.26407L8.7355 4.20357L1.75 11.1891V12.2496H2.8105L9.796 5.26407ZM10.8565 4.20357L11.917 3.14307L10.8565 2.08257L9.796 3.14307L10.8565 4.20357ZM3.4315 13.7496H0.25V10.5673L10.3263 0.491074C10.4669 0.350471 10.6576 0.271484 10.8565 0.271484C11.0554 0.271484 11.2461 0.350471 11.3868 0.491074L13.5085 2.61282C13.6491 2.75347 13.7281 2.9442 13.7281 3.14307C13.7281 3.34195 13.6491 3.53268 13.5085 3.67332L3.43225 13.7496H3.4315Z"
        fill="#465671"
      />
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 5V14.7448C13.7507 14.8432 13.732 14.9409 13.6949 15.0322C13.6579 15.1234 13.6032 15.2065 13.534 15.2766C13.4649 15.3468 13.3826 15.4026 13.2919 15.4409C13.2011 15.4792 13.1037 15.4993 13.0052 15.5H0.99475C0.79736 15.5 0.608043 15.4216 0.468397 15.2821C0.328751 15.1426 0.250199 14.9534 0.25 14.756V1.244C0.25 0.84125 0.58675 0.5 1.0015 0.5H9.24775L13.75 5ZM12.25 5.75H8.5V2H1.75V14H12.25V5.75ZM4 4.25H6.25V5.75H4V4.25ZM4 7.25H10V8.75H4V7.25ZM4 10.25H10V11.75H4V10.25Z"
        fill="#465671"
      />
    </svg>
  );
};

export const SaveIconAction = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 12.25V7.75H10.75V12.25H12.25V3.871L10.129 1.75H1.75V12.25H3.25ZM1 0.25H10.75L13.75 3.25V13C13.75 13.1989 13.671 13.3897 13.5303 13.5303C13.3897 13.671 13.1989 13.75 13 13.75H1C0.801088 13.75 0.610322 13.671 0.46967 13.5303C0.329018 13.3897 0.25 13.1989 0.25 13V1C0.25 0.801088 0.329018 0.610322 0.46967 0.46967C0.610322 0.329018 0.801088 0.25 1 0.25ZM4.75 9.25V12.25H9.25V9.25H4.75Z"
        fill="#465671"
      />
    </svg>
  );
};

export const Details = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0624 11.4L14.9639 11.9407C15.0195 11.974 15.0655 12.0212 15.0975 12.0776C15.1295 12.134 15.1463 12.1977 15.1463 12.2625C15.1463 12.3273 15.1295 12.391 15.0975 12.4474C15.0655 12.5038 15.0195 12.5509 14.9639 12.5842L8.38639 16.5307C8.26974 16.6008 8.13622 16.6378 8.00014 16.6378C7.86405 16.6378 7.73053 16.6008 7.61388 16.5307L1.03639 12.5842C0.98077 12.5509 0.934734 12.5038 0.90277 12.4474C0.870806 12.391 0.854004 12.3273 0.854004 12.2625C0.854004 12.1977 0.870806 12.134 0.90277 12.0776C0.934734 12.0212 0.98077 11.974 1.03639 11.9407L1.93789 11.4L8.00014 15.0375L14.0624 11.4ZM14.0624 7.87499L14.9639 8.41574C15.0195 8.44904 15.0655 8.49618 15.0975 8.55257C15.1295 8.60896 15.1463 8.67267 15.1463 8.73749C15.1463 8.80231 15.1295 8.86602 15.0975 8.92241C15.0655 8.9788 15.0195 9.02595 14.9639 9.05924L8.00014 13.2375L1.03639 9.05924C0.98077 9.02595 0.934734 8.9788 0.90277 8.92241C0.870806 8.86602 0.854004 8.80231 0.854004 8.73749C0.854004 8.67267 0.870806 8.60896 0.90277 8.55257C0.934734 8.49618 0.98077 8.44904 1.03639 8.41574L1.93789 7.87499L8.00014 11.5125L14.0624 7.87499ZM8.38564 0.981741L14.9639 4.92824C15.0195 4.96154 15.0655 5.00868 15.0975 5.06507C15.1295 5.12146 15.1463 5.18517 15.1463 5.24999C15.1463 5.31481 15.1295 5.37852 15.0975 5.43491C15.0655 5.4913 15.0195 5.53845 14.9639 5.57174L8.00014 9.74999L1.03639 5.57174C0.98077 5.53845 0.934734 5.4913 0.90277 5.43491C0.870806 5.37852 0.854004 5.31481 0.854004 5.24999C0.854004 5.18517 0.870806 5.12146 0.90277 5.06507C0.934734 5.00868 0.98077 4.96154 1.03639 4.92824L7.61388 0.981741C7.73053 0.911659 7.86405 0.874634 8.00014 0.874634C8.13622 0.874634 8.26974 0.911659 8.38639 0.981741H8.38564ZM8.00014 2.49899L3.41539 5.24999L8.00014 8.00099L12.5849 5.24999L8.00014 2.49899Z"
        fill="#465671"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 3.5H15.5V5H14V14.75C14 14.9489 13.921 15.1397 13.7803 15.2803C13.6397 15.421 13.4489 15.5 13.25 15.5H2.75C2.55109 15.5 2.36032 15.421 2.21967 15.2803C2.07902 15.1397 2 14.9489 2 14.75V5H0.5V3.5H4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H11C11.1989 0.5 11.3897 0.579018 11.5303 0.71967C11.671 0.860322 11.75 1.05109 11.75 1.25V3.5ZM12.5 5H3.5V14H12.5V5ZM5.75 7.25H7.25V11.75H5.75V7.25ZM8.75 7.25H10.25V11.75H8.75V7.25ZM5.75 2V3.5H10.25V2H5.75Z"
        fill="#465671"
      />
    </svg>
  );
};

export const ChartIcon = ({ color = '#465671' }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_662_9515)">
        <path
          d="M18.25 5.17537L14.9167 5.17537V17.6256H18.25V5.17537ZM6.58333 13.36H3.25V17.6256H6.58333V13.36ZM12.4167 10.3108H9.08333V17.6256H12.4167V10.3108Z"
          fill={color}
        />
        <path
          d="M13.181 1.53256L14.3594 2.58529L14.2861 2.65072L9.60355 6.83418L7.10355 4.60141L3.52605 7.79683L2.34772 6.7441L7.10355 2.4952L9.60355 4.72797L13.181 1.53256Z"
          fill={color}
        />
        <path
          d="M15.0164 0.939407L9.36364 2.11391L13.2072 6.42211L15.0164 0.939407Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_662_9515">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.75 0.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HamBurgerIcon = ({ color = '#465671' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 0.666672C13.6025 0.666672 17.3333 4.39751 17.3333 9C17.3333 13.6025 13.6025 17.3333 8.99999 17.3333C4.39749 17.3333 0.666656 13.6025 0.666656 9C0.666656 4.39751 4.39749 0.666672 8.99999 0.666672ZM8.99999 2.33334C5.31832 2.33334 2.33332 5.31834 2.33332 9C2.33332 12.6817 5.31832 15.6667 8.99999 15.6667C12.6817 15.6667 15.6667 12.6817 15.6667 9C15.6667 5.31834 12.6817 2.33334 8.99999 2.33334ZM12.1942 5.11417C12.3917 4.97584 12.66 4.99917 12.83 5.17C13 5.34 13.0217 5.60834 12.8825 5.80334C11.0658 8.34167 10.0658 9.70167 9.88416 9.88417C9.39582 10.3717 8.60416 10.3717 8.11582 9.88417C7.62832 9.39584 7.62832 8.60417 8.11582 8.11584C8.42749 7.80501 9.78666 6.80417 12.1942 5.11417ZM13.5833 8.16667C14.0433 8.16667 14.4167 8.54 14.4167 9C14.4167 9.46 14.0433 9.83334 13.5833 9.83334C13.1233 9.83334 12.75 9.46 12.75 9C12.75 8.54 13.1233 8.16667 13.5833 8.16667ZM4.41666 8.16667C4.87666 8.16667 5.24999 8.54 5.24999 9C5.24999 9.46 4.87666 9.83334 4.41666 9.83334C3.95666 9.83334 3.58332 9.46 3.58332 9C3.58332 8.54 3.95666 8.16667 4.41666 8.16667ZM6.34832 5.17C6.67332 5.495 6.67332 6.0225 6.34832 6.34834C6.02332 6.67334 5.49499 6.67334 5.16999 6.34834C4.84499 6.02334 4.84499 5.495 5.16999 5.17C5.49499 4.84501 6.02249 4.84501 6.34832 5.17ZM8.99999 3.58334C9.45999 3.58334 9.83332 3.95667 9.83332 4.41667C9.83332 4.87667 9.45999 5.25 8.99999 5.25C8.53999 5.25 8.16666 4.87667 8.16666 4.41667C8.16666 3.95667 8.53999 3.58334 8.99999 3.58334Z"
        fill={color}
      />
    </svg>
  );
};

export const MedicationIcon = ({ color = '#465671' }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22693 12.1826C1.11134 10.9181 1.27173 9.03575 2.58519 7.97824L5.61465 5.53911L9.65458 10.1182L6.62512 12.5574C5.31166 13.6149 3.34253 13.4471 2.22693 12.1826Z"
        fill={color}
      />
      <path
        d="M13.4185 3.24971C14.5341 4.5142 14.3737 6.39655 13.0602 7.45406L10.0308 9.89319L5.99084 5.31408L9.0203 2.87494C10.3338 1.81743 12.3029 1.98522 13.4185 3.24971Z"
        fill={color}
      />
      <path
        d="M17.7929 11.9977C17.7929 11.0386 17.4119 10.1188 16.7338 9.44067C16.0556 8.76251 15.1358 8.38153 14.1768 8.38153C13.2177 8.38153 12.2979 8.76251 11.6198 9.44067C10.9416 10.1188 10.5606 11.0386 10.5606 11.9977L14.1768 11.9977H17.7929Z"
        fill={color}
      />
      <path
        d="M10.5605 12.423C10.5605 13.382 10.9415 14.3018 11.6196 14.9799C12.2978 15.6581 13.2175 16.0391 14.1766 16.0391C15.1357 16.0391 16.0554 15.6581 16.7336 14.9799C17.4117 14.3018 17.7927 13.382 17.7927 12.423L14.1766 12.423H10.5605Z"
        fill={color}
      />
    </svg>
  );
};

export const PhysicianIcon = ({ color = '#465671' }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        filerule="evenodd"
        clipRule="evenodd"
        d="M2.28606 12.8685C1.03581 14.1187 0.333435 15.8144 0.333435 17.5825H13.6668C13.6668 15.8144 12.9644 14.1187 11.7141 12.8685C10.4639 11.6183 8.76821 10.9159 7.0001 10.9159C5.23199 10.9159 3.5363 11.6183 2.28606 12.8685ZM2.0001 5.08255C2.0001 7.84505 4.2376 10.0825 7.0001 10.0825C9.7626 10.0825 12.0001 7.84505 12.0001 5.08255C12.0001 2.32005 9.7626 0.0825462 7.0001 0.0825462C4.2376 0.0825462 2.0001 2.32005 2.0001 5.08255ZM5.78823 12.2475L5.77928 11.6225L4.57138 11.6398C4.34912 11.643 4.17075 11.7856 4.17322 11.9581L4.19559 13.5204C4.2089 14.4494 5.09044 15.2081 6.23524 15.3405L6.24233 15.8351C6.25592 16.7844 7.25822 17.5395 8.48142 17.522C9.51176 17.5072 10.37 16.9486 10.6066 16.2055C11.1107 16.0864 11.476 15.7199 11.4699 15.2915C11.4624 14.7737 10.9157 14.3618 10.2485 14.3713C9.58136 14.3809 9.04663 14.8083 9.05405 15.3261C9.05951 15.7073 9.35733 16.0312 9.78097 16.1711C9.58558 16.5873 9.07522 16.8884 8.47247 16.8971C7.69418 16.9082 7.05624 16.4276 7.04759 15.8236L7.04051 15.329C8.18105 15.1635 9.0405 14.3802 9.0272 13.4512L9.00482 11.8889C9.00235 11.7164 8.81997 11.579 8.59772 11.5822L7.38981 11.5995L7.39876 12.2244L8.20403 12.2129L8.22193 13.4628C8.23182 14.1533 7.51912 14.723 6.6293 14.7357C5.73948 14.7485 5.01075 14.1995 5.00086 13.5089L4.98296 12.259L5.78823 12.2475ZM9.85932 15.3145C9.85685 15.142 10.0352 14.9995 10.2575 14.9963C10.4797 14.9931 10.6621 15.1305 10.6646 15.303C10.6671 15.4755 10.4887 15.618 10.2664 15.6212C10.0442 15.6244 9.86179 15.487 9.85932 15.3145Z"
        fill={color}
      />
    </svg>
  );
};

export const AnalysisIcon = ({ color = '#465671' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_662_9353)">
        <path
          d="M15.0259 14.3467L18.5951 17.915L17.4159 19.0942L13.8476 15.525C12.5199 16.5894 10.8684 17.1683 9.16675 17.1659C5.02675 17.1659 1.66675 13.8059 1.66675 9.66588C1.66675 5.52588 5.02675 2.16588 9.16675 2.16588C13.3067 2.16588 16.6667 5.52588 16.6667 9.66588C16.6692 11.3675 16.0903 13.019 15.0259 14.3467ZM13.3542 13.7284C14.4118 12.6408 15.0025 11.1829 15.0001 9.66588C15.0001 6.44255 12.3892 3.83255 9.16675 3.83255C5.94341 3.83255 3.33341 6.44255 3.33341 9.66588C3.33341 12.8884 5.94341 15.4992 9.16675 15.4992C10.6838 15.5016 12.1417 14.911 13.2292 13.8534L13.3542 13.7284Z"
          fill={color}
        />
        <path
          d="M12.8925 6.03687L11.0857 6.03687V12.7854H12.8925V6.03687ZM6.56866 10.4733H4.76186V12.7854H6.56866V10.4733ZM9.73056 8.82049H7.92376V12.7854H9.73056V8.82049Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_662_9353">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SmallFilterIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1257_39183)">
        <path
          d="M12 2V3.11111H11.4444L8.66667 7.27778V12H5.33333V7.27778L2.55556 3.11111H2V2H12ZM3.89111 3.11111L6.44444 6.94111V10.8889H7.55556V6.94111L10.1089 3.11111H3.89111Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1257_39183">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SmallInfoIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 3.5H7.7V4.9H6.3V3.5ZM6.3 6.3H7.7V10.5H6.3V6.3Z"
        fill="white"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2829_2751)">
        <path
          d="M15.0259 13.8475L18.5951 17.4158L17.4159 18.595L13.8476 15.0258C12.5199 16.0901 10.8684 16.669 9.16675 16.6666C5.02675 16.6666 1.66675 13.3066 1.66675 9.16663C1.66675 5.02663 5.02675 1.66663 9.16675 1.66663C13.3067 1.66663 16.6667 5.02663 16.6667 9.16663C16.6692 10.8683 16.0903 12.5197 15.0259 13.8475ZM13.3542 13.2291C14.4118 12.1415 15.0025 10.6837 15.0001 9.16663C15.0001 5.94329 12.3892 3.33329 9.16675 3.33329C5.94341 3.33329 3.33341 5.94329 3.33341 9.16663C3.33341 12.3891 5.94341 15 9.16675 15C10.6838 15.0023 12.1417 14.4117 13.2292 13.3541L13.3542 13.2291Z"
          fill="#387ED0"
        />
      </g>
      <defs>
        <clipPath id="clip0_2829_2751">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowDownOpacityLow = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="#accaed" />
      <path
        d="M9.52938 11.9625L6.53188 8.53625C6.17813 8.13313 6.46563 7.5 7.00251 7.5H12.9975C13.1177 7.4999 13.2353 7.53443 13.3363 7.59946C13.4374 7.6645 13.5175 7.75727 13.5672 7.86668C13.6168 7.97609 13.6339 8.0975 13.6163 8.21636C13.5988 8.33522 13.5473 8.4465 13.4681 8.53688L10.4706 11.9619C10.412 12.029 10.3396 12.0828 10.2585 12.1197C10.1773 12.1565 10.0892 12.1756 10 12.1756C9.91086 12.1756 9.82274 12.1565 9.74156 12.1197C9.66039 12.0828 9.58805 12.029 9.52938 11.9619V11.9625Z"
        fill="#f3f8ff"
      />
    </svg>
  );
};

export const TickSvg = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49956 7.879L12.3936 0.984249L13.4548 2.04475L5.49956 10L0.726562 5.227L1.78706 4.1665L5.49956 7.879Z"
        fill="#387ED0"
      />
    </svg>
  );
};

export const CalanderIcon = ({ color = '#387ED0' }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2491_23477)">
        <path
          d="M13.5332 2.25H16.5332C16.7321 2.25 16.9229 2.32902 17.0635 2.46967C17.2042 2.61032 17.2832 2.80109 17.2832 3V15C17.2832 15.1989 17.2042 15.3897 17.0635 15.5303C16.9229 15.671 16.7321 15.75 16.5332 15.75H3.0332C2.83429 15.75 2.64353 15.671 2.50287 15.5303C2.36222 15.3897 2.2832 15.1989 2.2832 15V3C2.2832 2.80109 2.36222 2.61032 2.50287 2.46967C2.64353 2.32902 2.83429 2.25 3.0332 2.25H6.0332V0.75H7.5332V2.25H12.0332V0.75H13.5332V2.25ZM12.0332 3.75H7.5332V5.25H6.0332V3.75H3.7832V6.75H15.7832V3.75H13.5332V5.25H12.0332V3.75ZM15.7832 8.25H3.7832V14.25H15.7832V8.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2491_23477">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.783203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Line = () => {
  return (
    <svg
      width="1"
      height="24"
      viewBox="0 0 1 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke="#EDECF6" />
    </svg>
  );
};

export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3097_30249)">
        <path
          d="M9.75 10.5H8.25C7.0197 10.4995 5.81267 10.8354 4.75941 11.4712C3.70614 12.107 2.8467 13.0186 2.274 14.1075C2.2579 13.9054 2.2499 13.7027 2.25 13.5C2.25 9.35775 5.60775 6 9.75 6V1.875L17.625 8.25L9.75 14.625V10.5ZM8.25 9H11.25V11.481L15.2408 8.25L11.25 5.019V7.5H9.75C8.88769 7.49903 8.03534 7.68436 7.25129 8.04332C6.46724 8.40227 5.76999 8.92637 5.20725 9.57975C6.17574 9.19591 7.20822 8.99919 8.25 9Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_3097_30249">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AddNoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3097_30254)">
        <path
          d="M15.75 6V15.7448C15.7507 15.8432 15.732 15.9409 15.6949 16.0322C15.6579 16.1234 15.6032 16.2065 15.534 16.2766C15.4649 16.3468 15.3826 16.4026 15.2919 16.4409C15.2011 16.4792 15.1037 16.4993 15.0052 16.5H2.99475C2.79736 16.5 2.60804 16.4216 2.4684 16.2821C2.32875 16.1426 2.2502 15.9534 2.25 15.756V2.244C2.25 1.84125 2.58675 1.5 3.0015 1.5H11.2477L15.75 6ZM14.25 6.75H10.5V3H3.75V15H14.25V6.75ZM6 5.25H8.25V6.75H6V5.25ZM6 8.25H12V9.75H6V8.25ZM6 11.25H12V12.75H6V11.25Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_3097_30254">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3097_30260)">
        <path
          d="M5.25 14.25V9.75H12.75V14.25H14.25V5.871L12.129 3.75H3.75V14.25H5.25ZM3 2.25H12.75L15.75 5.25V15C15.75 15.1989 15.671 15.3897 15.5303 15.5303C15.3897 15.671 15.1989 15.75 15 15.75H3C2.80109 15.75 2.61032 15.671 2.46967 15.5303C2.32902 15.3897 2.25 15.1989 2.25 15V3C2.25 2.80109 2.32902 2.61032 2.46967 2.46967C2.61032 2.32902 2.80109 2.25 3 2.25ZM6.75 11.25V14.25H11.25V11.25H6.75Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_3097_30260">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DetailsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3097_30265)">
        <path
          d="M15.0624 11.4004L15.9639 11.9411C16.0195 11.9744 16.0655 12.0215 16.0975 12.0779C16.1295 12.1343 16.1463 12.198 16.1463 12.2629C16.1463 12.3277 16.1295 12.3914 16.0975 12.4478C16.0655 12.5042 16.0195 12.5513 15.9639 12.5846L9.38639 16.5311C9.26974 16.6012 9.13622 16.6382 9.00014 16.6382C8.86405 16.6382 8.73053 16.6012 8.61388 16.5311L2.03639 12.5846C1.98077 12.5513 1.93473 12.5042 1.90277 12.4478C1.87081 12.3914 1.854 12.3277 1.854 12.2629C1.854 12.198 1.87081 12.1343 1.90277 12.0779C1.93473 12.0215 1.98077 11.9744 2.03639 11.9411L2.93789 11.4004L9.00014 15.0379L15.0624 11.4004ZM15.0624 7.87536L15.9639 8.41611C16.0195 8.4494 16.0655 8.49654 16.0975 8.55293C16.1295 8.60932 16.1463 8.67304 16.1463 8.73786C16.1463 8.80268 16.1295 8.86639 16.0975 8.92278C16.0655 8.97917 16.0195 9.02631 15.9639 9.05961L9.00014 13.2379L2.03639 9.05961C1.98077 9.02631 1.93473 8.97917 1.90277 8.92278C1.87081 8.86639 1.854 8.80268 1.854 8.73786C1.854 8.67304 1.87081 8.60932 1.90277 8.55293C1.93473 8.49654 1.98077 8.4494 2.03639 8.41611L2.93789 7.87536L9.00014 11.5129L15.0624 7.87536ZM9.38564 0.982107L15.9639 4.92861C16.0195 4.9619 16.0655 5.00904 16.0975 5.06543C16.1295 5.12182 16.1463 5.18554 16.1463 5.25036C16.1463 5.31518 16.1295 5.37889 16.0975 5.43528C16.0655 5.49167 16.0195 5.53881 15.9639 5.57211L9.00014 9.75036L2.03639 5.57211C1.98077 5.53881 1.93473 5.49167 1.90277 5.43528C1.87081 5.37889 1.854 5.31518 1.854 5.25036C1.854 5.18554 1.87081 5.12182 1.90277 5.06543C1.93473 5.00904 1.98077 4.9619 2.03639 4.92861L8.61388 0.982107C8.73053 0.912025 8.86405 0.875 9.00014 0.875C9.13622 0.875 9.26974 0.912025 9.38639 0.982107H9.38564ZM9.00014 2.49936L4.41539 5.25036L9.00014 8.00136L13.5849 5.25036L9.00014 2.49936Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_3097_30265">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ExtractDataIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3097_30270)">
        <path
          d="M15 2.25L16.5 5.25V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V5.253L3 2.25H15ZM15 6.75H3V14.25H15V6.75ZM9 7.5L12 10.5H9.75V13.5H8.25V10.5H6L9 7.5ZM14.073 3.75H3.927L3.17775 5.25H14.823L14.073 3.75Z"
          fill="#465671"
        />
      </g>
      <defs>
        <clipPath id="clip0_3097_30270">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InfoTconPhysician = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M8.00001 14.6663C4.31811 14.6663 1.33334 11.6815 1.33334 7.99967C1.33334 4.31777 4.31811 1.33301 8.00001 1.33301C11.6819 1.33301 14.6667 4.31777 14.6667 7.99967C14.6667 11.6815 11.6819 14.6663 8.00001 14.6663ZM8.00001 13.333C10.9455 13.333 13.3333 10.9452 13.3333 7.99967C13.3333 5.05415 10.9455 2.66634 8.00001 2.66634C5.05449 2.66634 2.66668 5.05415 2.66668 7.99967C2.66668 10.9452 5.05449 13.333 8.00001 13.333ZM7.33334 4.66634H8.66668V5.99967H7.33334V4.66634ZM7.33334 7.33301H8.66668V11.333H7.33334V7.33301Z"
          fill="white"
          fill-opacity="0.5"
        />
      </g>
    </svg>
  );
};

export const CaretSmallIcon = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9625 5.97062L3.53625 8.96812C3.13313 9.32187 2.5 9.03437 2.5 8.49749L2.5 2.50249C2.4999 2.38234 2.53443 2.2647 2.59946 2.16367C2.6645 2.06264 2.75727 1.9825 2.86668 1.93284C2.97609 1.88318 3.0975 1.86611 3.21636 1.88367C3.33522 1.90123 3.4465 1.95269 3.53688 2.03187L6.96188 5.02937C7.02901 5.08803 7.08281 5.16038 7.11968 5.24155C7.15654 5.32272 7.17561 5.41084 7.17561 5.49999C7.17561 5.58914 7.15654 5.67726 7.11968 5.75844C7.08281 5.83961 7.02901 5.91195 6.96188 5.97062H6.9625Z"
        fill="#465671"
      />
    </svg>
  );
};
