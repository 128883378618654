import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import { MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/system';
import { useSession } from 'next-auth/react';
import { useMutation } from 'react-query';
import { updatePrivacyPolicies } from '../../queries/user';
import { toast } from 'react-toastify';

export default function ScrollDialog() {
  const session = useSession();
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
 
  const [openHealthTerms, setOpenHealthTerms] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [totalSteps, setTotalSteps] = React.useState(0);

  let hasRecentlyAcceptedPolicy = false;
  let hasRecentlyAcceptedTerms = false;

  if (typeof localStorage !== 'undefined') {
    hasRecentlyAcceptedPolicy =
      localStorage.getItem('hasAcceptedPolicy') === 'true';
    hasRecentlyAcceptedTerms =
      localStorage.getItem('hasAcceptedTerms') === 'true';
  }

  React.useEffect(() => {
    if (
      session?.data?.user?.hasAcceptedTerms === false &&
      !hasRecentlyAcceptedTerms
    ) {
      setOpenHealthTerms(true);
      setTotalSteps(totalSteps => totalSteps + 1);
    }
    if (
      session?.data?.user?.hasAcceptedPolicy === false &&
      !hasRecentlyAcceptedPolicy
    ) {
      setOpenPrivacyPolicy(true);
      setTotalSteps(totalSteps => totalSteps + 1);
    }
  }, [session?.data]);

  const updatePoliciesAndHealthTerms = useMutation({
    mutationFn: variables => {
      return updatePrivacyPolicies(variables.payload);
    },
    onSuccess: (data, variables) => {
      if (variables?.from === 'Privacy') {
        setOpenPrivacyPolicy(false);
        openHealthTerms && setActiveStep(1);
      }

      if (variables?.from === 'Health') {
        setOpenHealthTerms(false);
        openPrivacyPolicy && setActiveStep(1);
      }
    },
    onError: (error, variables, context) => {
      toast.error(
        `Error while accepting ${
          variables?.from === 'Privacy'
            ? 'privacy policy'
            : 'terms and condtions'
        }`
      );
    }
  });
  const [scroll] = React.useState('paper');

  const theme = useTheme();
  const listInnerRef = React.useRef();

  const handleNext = from => {
    if (from === 'Privacy') {
      updatePoliciesAndHealthTerms.mutate({
        from: 'Privacy',
        payload: {
          type: {
            hasAcceptedPolicy: true
          }
        }
      });
      localStorage.setItem('hasAcceptedPolicy', true);
    }

    if (from === 'Health') {
      updatePoliciesAndHealthTerms.mutate({
        from: 'Health',
        payload: {
          type: {
            hasAcceptedTerms: true
          }
        }
      });
      localStorage.setItem('hasAcceptedTerms', true);
      setOpenHealthTerms(false);
    }
  };

  const handleClose = () => {};

  const handleClose1 = () => {};

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openPrivacyPolicy || openHealthTerms) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openPrivacyPolicy, openHealthTerms]);

  React.useEffect(() => {
    const handleScroll = () => {
      // Get the button element
      const buttonElement = document.getElementById('your-button-id');

      if (buttonElement) {
        // Calculate its position relative to the viewport
        const buttonRect = buttonElement.getBoundingClientRect();

        // Check if the button is in the viewport
        if (buttonRect.top >= 0 && buttonRect.bottom <= window.innerHeight) {
          console.log('Button is visible!');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={listInnerRef}>
      <Dialog
        maxWidth={'lg'}
        open={openPrivacyPolicy}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {' '}
          <h2
            style={{
              margin: 0,
              color: '#2e2e2e',
              fontSize: '2rem',
              fontWeight: 'bolder',
              letterSpacing: '-.9px',
              lineHeight: '1.1'
            }}
          >
            Vindeca Health Privacy Policy
          </h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MobileStepper
            variant="text"
            steps={totalSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={() => handleNext('Privacy')}
                disabled={activeStep === totalSteps}
              >
                Accept and continue
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
          />
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={'lg'}
        open={openHealthTerms}
        onClose={handleClose1}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {' '}
          <h2
            style={{
              margin: 0,
              color: '#2e2e2e',
              fontSize: '2rem',
              fontWeight: 'bolder',
              letterSpacing: '-.9px',
              lineHeight: '1.1'
            }}
          >
            Vindeca Health Terms of Service
          </h2>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TermsOfService />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MobileStepper
            variant="text"
            steps={totalSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={() => handleNext('Health')}
                disabled={activeStep === totalSteps}
              >
                Accept and continue
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
