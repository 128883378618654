import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { InfoIcon } from '../../icons/icons';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '12px',
    // border: '1px solid #dadde9',
    // padding: '10px'
  }
}));

export default function NavBarTooltip({
  info,
  width = 21,
  height = 20,
  children
}) {
  return (
    <div style={{ cursor: 'pointer' }}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="gray">{info}</Typography>
          </React.Fragment>
        }
        placement="bottom-end"
      >
        <span>{children}</span>
      </HtmlTooltip>
    </div>
  );
}
