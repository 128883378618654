import { useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/material/styles';

export function checkRequiredSized(size) {
  const theme = useTheme();

  const matchUpMd = useMediaQuery(theme.breakpoints.up(size));

  return matchUpMd;
}

export function checkRequiredResponsiveSizeUsingCssMediaQuery(width) {
  
    const matchUpMd = useMediaQuery(width);
  
    return matchUpMd;
  }
