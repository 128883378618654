import { getFormattedDate } from '../utils/date.utils';

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 240;
export const appDrawerWidth = 320;
export const alignLeftAnalysistableFields = [
  'Physician Name',
  'Patient Name',
  'Drug Name',
  'Hospital Name',
  'Therapeutics'
];

export const showMedication =
  process.env.NEXT_PUBLIC_SHOW_MEDICATION !== 'false';
export const showUtilization =
  process.env.NEXT_PUBLIC_SHOW_UTILIZATION !== 'false';
export const showAnalysis = process.env.NEXT_PUBLIC_SHOW_ANALYSIS !== 'false';
export const showPhysician = process.env.NEXT_PUBLIC_SHOW_PHYSICIAN !== 'false';

export const orginazation_name = process.env.NEXT_PUBLIC_ORG_NAME || '';

export const getEndDate = org_name => {
  return org_name === 'vindeca' ? '2023-03-26' : getFormattedDate(new Date());
};

export const getStartDate = org_name => {
  return org_name === 'vindeca'
    ? '2022-08-01'
    : getFormattedDate(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
      );
};

export const defaultAnalysisPage = {
  tabs: [
    {
      label: 'Popular Templates',
      id: 'popular-templates',
      deleteDisabled: true
    },
    { label: 'Tab 1', id: '4JtD0eKJu0plhP4-N0ozn' }
  ],
  activeTab: 'popular-templates',
  '4JtD0eKJu0plhP4-N0ozn': {
    filterOptions: [
      {
        key: 'Patient',
        id: 'Patient-Patient Name-name',
        accessorKey: 'Patient Name',
        header: 'Patient Name',
        combinedValue: 'Patient-Patient Name-name',
        selectFilters: [],
        attributeName: 'Patient Name',
        viewOptions: ['List'],
        table: ['Patient'],
        column: ['name'],
        aggregation: null
      },
      {
        key: 'Date',
        id: 'Date-Date Range (start, end)-dateAdministered',
        accessorKey: 'Date Range (start, end)',
        header: 'Date Range (start, end)',
        combinedValue: 'Date-Date Range (start, end)-dateAdministered',
        selectFilters: [],
        attributeName: 'Date Range (start, end)',
        viewOptions: ['Daily'],
        table: ['DrugAdministered'],
        column: ['dateAdministered'],
        aggregation: null
      },
      {
        key: 'Therapeutic',
        id: 'Therapeutic-DRG-shortDescription',
        accessorKey: 'DRG',
        header: 'DRG',
        combinedValue: 'Therapeutic-DRG-shortDescription',
        selectFilters: [],
        attributeName: 'DRG',
        viewOptions: ['List'],
        table: ['hlp_drg_global'],
        column: ['shortDescription'],
        aggregation: null
      },
      {
        key: 'Metrics',
        id: 'Metrics-Spend on Drug-',
        accessorKey: 'Spend on Drug',
        header: 'Spend on Drug',
        combinedValue: 'Metrics-Spend on Drug-',
        selectFilters: [],
        attributeName: 'Spend on Drug',
        viewOptions: ['List'],
        table: ['DrugAdministered'],
        column: null,
        aggregation: true
      },
      {
        key: 'Encounter',
        id: 'Encounter-Length of Stay-',
        accessorKey: 'Length of Stay',
        header: 'Length of Stay',
        combinedValue: 'Encounter-Length of Stay-',
        selectFilters: [],
        attributeName: 'Length of Stay',
        viewOptions: ['List'],
        table: ['Admission'],
        column: null,
        aggregation: true
      }
    ],
    page: 1
  }
};

export const utilizationColoringReasonsList = [
  {
    reasonId: 11001,
    description: 'new therapeutic class',
    reasonText:
      'drugs -$1- belonging to a new therapeutics class are introduced',
    type: 'medicationUtilization'
  },
  {
    reasonId: 12001,
    description: 'replaced with clinically equivalent drug',
    reasonText: '$1 replaced with $2',
    type: 'medicationUtilization'
  },
  {
    reasonId: 12002,
    description: 'add clinically equivalent drug',
    reasonText: 'introduced $2',
    type: 'medicationUtilization'
  },
  {
    reasonId: 13000,
    description: 'add a new formulation of existing drug',
    reasonText: 'add a newformulation of $1',
    type: 'medicationUtilization'
  },
  {
    reasonId: 15001,
    description: 'change in drug price',
    reasonText: 'price of $1 increased from $2 to $3',
    type: 'drugPrice'
  },
  {
    reasonId: 15002,
    description: 'change in quantity consumed',
    reasonText: 'consumption of $1 has increased from $2 to $3',
    type: 'medicationUtilizationPerPatient'
  },
  {
    reasonId: 15003,
    description: 'change in exposure',
    reasonText: 'number of patients reciving $1 has increased from $2% to $3%',
    type: 'patientPercentage'
  }
];

export const getLastAvailiableDate = orginazation_name => {
  return orginazation_name === 'vindeca'
    ? process.env.NEXT_PUBLIC_LAST_AVAILIABLE_DATE
    : new Date();
};

export const analysisAttributeFilterOptions = {
  'Physician Name': {
    key: 'Physician',
    id: 'Physician-Physician Name-name',
    accessorKey: 'Physician Name',
    header: 'Physician Name',
    combinedValue: 'Physician-Physician Name-name',
    selectFilters: [],
    attributeName: 'Physician Name',
    viewOptions: ['List'],
    table: ['Physician'],
    column: ['name'],
    aggregation: null
  },
  'Primary Diagnosis': {
    key: 'Diagnosis',
    id: 'Diagnosis-Primary Diagnosis-shortDescription',
    accessorKey: 'Primary Diagnosis',
    header: 'Primary Diagnosis',
    combinedValue: 'Diagnosis-Primary Diagnosis-shortDescription',
    selectFilters: [],
    attributeName: 'Primary Diagnosis',
    viewOptions: ['List'],
    table: ['hlp_drg_global'],
    column: ['shortDescription'],
    aggregation: null
  },
  'All Diagnosis': {
    key: 'Diagnosis',
    id: 'Diagnosis-All Diagnosis-shortDescription',
    accessorKey: 'All Diagnosis',
    header: 'All Diagnosis',
    combinedValue: 'Diagnosis-All Diagnosis-shortDescription',
    selectFilters: [],
    attributeName: 'All Diagnosis',
    viewOptions: ['List', 'Agg-OR', 'Agg-AND'],
    table: ['hlp_drg_global'],
    column: ['shortDescription'],
    aggregation: null
  },
  'Drug Name': {
    key: 'Medication',
    id: 'Medication-Drug Name-genericName',
    accessorKey: 'Drug Name',
    header: 'Drug Name',
    combinedValue: 'Medication-Drug Name-genericName',
    selectFilters: [],
    attributeName: 'Drug Name',
    viewOptions: ['List'],
    table: ['GlobalDrug'],
    column: ['genericName'],
    aggregation: null
  },

  'Patient Count': {
    key: 'Metrics',
    id: 'Metrics-Patient Count-',
    accessorKey: 'Patient Count',
    header: 'Patient Count',
    combinedValue: 'Metrics-Patient Count-',
    selectFilters: [],
    attributeName: 'Patient Count',
    viewOptions: ['List'],
    table: ['Admission'],
    column: null,
    aggregation: true
  },
  'Spend on Drug': {
    key: 'Metrics',
    id: 'Metrics-Spend on Drug-',
    accessorKey: 'Spend on Drug',
    header: 'Spend on Drug',
    combinedValue: 'Metrics-Spend on Drug-',
    selectFilters: [],
    attributeName: 'Spend on Drug',
    viewOptions: ['List'],
    table: ['DrugAdministered'],
    column: null,
    aggregation: true
  },
  'Length of Stay': {
    key: 'Encounter',
    id: 'Encounter-Length of Stay-',
    accessorKey: 'Length of Stay',
    header: 'Length of Stay',
    combinedValue: 'Encounter-Length of Stay-',
    selectFilters: [],
    attributeName: 'Length of Stay',
    viewOptions: ['List'],
    table: ['Admission'],
    column: null,
    aggregation: true
  },
  'Hospital Name': {
    key: 'Facility',
    id: 'Facility-Hospital Name-name',
    accessorKey: 'Hospital Name',
    header: 'Hospital Name',
    combinedValue: 'Facility-Hospital Name-name',
    selectFilters: [],
    attributeName: 'Hospital Name',
    viewOptions: ['List'],
    table: ['Hospital'],
    column: ['name'],
    aggregation: null
  },
  'Date Range (start, end)': {
    key: 'Date',
    id: 'Date-Date Range (start, end)-dateAdministered',
    accessorKey: 'Date Range (start, end)',
    header: 'Date Range (start, end)',
    combinedValue: 'Date-Date Range (start, end)-dateAdministered',
    selectFilters: [],
    attributeName: 'Date Range (start, end)',
    viewOptions: ['Daily'],
    table: ['DrugAdministered'],
    column: ['dateAdministered'],
    aggregation: null
  },
  'Encounter Id': {
    key: 'Encounter',
    id: 'Encounter-Encounter Id-id',
    accessorKey: 'Encounter Id',
    header: 'Encounter Id',
    combinedValue: 'Encounter-Encounter Id-id',
    selectFilters: [],
    attributeName: 'Encounter Id',
    viewOptions: ['List'],
    table: ['Admission'],
    column: ['id'],
    aggregation: null
  },
  Age: {
    key: 'Patient',
    id: 'Patient-Age-age',
    accessorKey: 'Age',
    header: 'Age',
    combinedValue: 'Patient-Age-age',
    selectFilters: [],
    attributeName: 'Age',
    viewOptions: ['List'],
    table: ['Admission'],
    column: ['age'],
    aggregation: null
  },
  Gender: {
    key: 'Patient',
    id: 'Patient-Gender-gender',
    accessorKey: 'Gender',
    header: 'Gender',
    combinedValue: 'Patient-Gender-gender',
    selectFilters: [],
    attributeName: 'Gender',
    viewOptions: ['List'],
    table: ['Admission'],
    column: ['gender'],
    aggregation: null
  },
  'Admission Date': {
    key: 'Encounter',
    id: 'Encounter-Admission Date-admissionTime',
    accessorKey: 'Admission Date',
    header: 'Admission Date',
    combinedValue: 'Encounter-Admission Date-admissionTime',
    selectFilters: [],
    attributeName: 'Admission Date',
    viewOptions: ['List'],
    table: ['Admission'],
    column: ['admissionTime'],
    aggregation: null
  },
  'Drug Count': {
    key: 'Metrics',
    id: 'Metrics-Drug Count-',
    accessorKey: 'Drug Count',
    header: 'Drug Count',
    combinedValue: 'Metrics-Drug Count-',
    selectFilters: [],
    attributeName: 'Drug Count',
    viewOptions: ['List'],
    table: ['GlobalDrug'],
    column: null,
    aggregation: true
  },
  'Medication Expense Score': {
    key: 'Physician',
    id: 'Physician-Medication Expense Score-',
    accessorKey: 'Medication Expense Score',
    header: 'Medication Expense Score',
    combinedValue: 'Physician-Medication Expense Score-',
    selectFilters: [],
    attributeName: 'Medication Expense Score',
    viewOptions: ['List'],
    table: ['Physician'],
    column: null,
    aggregation: true
  },

  'Physician Count': {
    key: 'Metrics',
    id: 'Metrics-Physician Count-',
    accessorKey: 'Physician Count',
    header: 'Physician Count',
    combinedValue: 'Metrics-Physician Count-',
    selectFilters: [],
    attributeName: 'Physician Count',
    viewOptions: ['List'],
    table: ['DrugAdministered'],
    column: null,
    aggregation: true
  },
  'Drug Price': {
    key: 'Medication',
    id: 'Medication-Drug Price-',
    accessorKey: 'Drug Price',
    header: 'Drug Price',
    combinedValue: 'Medication-Drug Price-',
    selectFilters: [],
    attributeName: 'Drug Price',
    viewOptions: ['List'],
    table: ['DrugAdministered'],
    column: null,
    aggregation: true
  },
  DRG: {
    key: 'Therapeutic',
    id: 'Therapeutic-DRG-shortDescription',
    accessorKey: 'DRG',
    header: 'DRG',
    combinedValue: 'Therapeutic-DRG-shortDescription',
    selectFilters: [],
    attributeName: 'DRG',
    viewOptions: ['List'],
    table: ['hlp_drg_global'],
    column: ['shortDescription'],
    aggregation: null
  },
  Route: {
    key: 'Medication',
    id: 'Medication-Route-route',
    accessorKey: 'Route',
    header: 'Route',
    combinedValue: 'Medication-Route-route',
    selectFilters: [],
    attributeName: 'Route',
    viewOptions: ['List'],
    table: ['GlobalDrug'],
    column: ['route'],
    aggregation: null
  },
  Strength: {
    key: 'Medication',
    id: 'Medication-Strength-strength',
    accessorKey: 'Strength',
    header: 'Strength',
    combinedValue: 'Medication-Strength-strength',
    selectFilters: [],
    attributeName: 'Strength',
    viewOptions: ['List', 'Agg-OR'],
    table: ['GlobalDrug'],
    column: ['strength'],
    aggregation: null
  },
  NDC: {
    key: 'Medication',
    id: 'Medication-NDC-ndc',
    accessorKey: 'NDC',
    header: 'NDC',
    combinedValue: 'Medication-NDC-ndc',
    selectFilters: [],
    attributeName: 'NDC',
    viewOptions: ['List'],
    table: ['GlobalDrug'],
    column: ['ndc'],
    aggregation: null
  }
};

export const analysisTemplates = {
  'high-cost-therapeutics': {
    name: 'High Cost Therapeutics',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Length of Stay'],
        analysisAttributeFilterOptions['Drug Count']
      ]
    }
  },
  'high-spending-drugs': {
    name: 'High-Spending Drugs',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Physician Count'],
        analysisAttributeFilterOptions['Drug Price']
      ]
    }
  },
  'high-spend-physician': {
    name: 'High Spend Physician',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Medication Expense Score']
        // analysisAttributeFilterOptions['Length of Stay']
      ]
    }
  },
  'high-cost-patients': {
    name: 'High Cost Patients',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Encounter Id'],
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Admission Date'],
        analysisAttributeFilterOptions['Length of Stay'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Drug Count'],
        analysisAttributeFilterOptions['Age'],
        analysisAttributeFilterOptions['Gender']
      ]
    }
  },
  'medication-utilization-by-therapeutic': {
    name: 'Medication Utilization by Therapeutic',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Patient Count']
      ]
    }
  },
  'medication-utilization-by-physician': {
    name: 'Medication Utilization by Physician',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Patient Count']
      ]
    }
  },
  'physician-analysis-by-therapeutic': {
    name: 'Physician Analysis by Therapeutic',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Patient Count']
      ]
    }
  },
  'overview-analysis': {
    name: 'Overview',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Length of Stay']
      ]
    }
  },
  'hospital-overview-analysis': {
    name: 'Hospital Overview ',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Length of Stay']
      ]
    }
  },
  'utilization-analysis-therapeutic': {
    name: 'Utilization',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Physician Name'],

        analysisAttributeFilterOptions['Drug Name'],

        analysisAttributeFilterOptions['Patient Count'],

        analysisAttributeFilterOptions['Spend on Drug']
      ]
    }
  },
  'utilization-analysis-medication': {
    name: 'Utilization',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug']
      ]
    }
  },
  'medication-compare-analysis': {
    name: 'Utilization',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug']
      ]
    }
  },
  'physician-analysis': {
    name: 'Physician',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Patient Count'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Length of Stay']
      ]
    }
  },
  'top-medication-viewall-utilization-therapeutic-search': {
    name: 'Top Medications',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['All Diagnosis'],
        analysisAttributeFilterOptions['Hospital Name']
      ]
    }
  },
  'top-physician-viewall-utilization-therapeutic-search': {
    name: 'Top Physicians',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['All Diagnosis'],
        analysisAttributeFilterOptions['Hospital Name']
      ]
    }
  },
  'top-therapeutic-viewall-utilization-medication-search': {
    name: 'Top Therapeutics',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Strength'],
        analysisAttributeFilterOptions['Route'],
        analysisAttributeFilterOptions['NDC']
      ]
    }
  },
  'top-physician-viewall-utilization-medication-search': {
    name: 'Top Physicians',
    defaultData: {
      filterOptions: [
        analysisAttributeFilterOptions['Date Range (start, end)'],
        analysisAttributeFilterOptions['Physician Name'],
        analysisAttributeFilterOptions['Primary Diagnosis'],
        analysisAttributeFilterOptions['Spend on Drug'],
        analysisAttributeFilterOptions['Drug Name'],
        analysisAttributeFilterOptions['Hospital Name'],
        analysisAttributeFilterOptions['Strength'],
        analysisAttributeFilterOptions['Route'],
        analysisAttributeFilterOptions['NDC']
      ]
    }
  }
};

export const analysisFilterNotIncludeDollar = {
  'Patient Count': true,
  'Drug Count': true,
  'Physician Count': true,
  'Medication Expense Score': true,
  'Length of Stay': true
};

export const analysisPaths = {
  '/overview': '/overview-analysis?from=template',
  '/overview/hospital': '/hospital-overview-analysis?from=template',
  '/utilization-therapeutic': '/utilization-analysis-therapeutic?from=template',
  '/utilization-medication': '/utilization-analysis-medication?from=template',
  '/medication': '/medication-compare-analysis?from=template',
  '/physician': '/physician-analysis?from=template',
  '/physician/analogy': '/physician-analysis?from=template'
};

export const analysisSelectedTemplates = {
  '/overview': '?from=template&selectedTemplate=overview-analysis',
  '/overview/hospital':
    '?from=template&selectedTemplate=hospital-overview-analysis',
  '/utilization-therapeutic':
    '?from=template&selectedTemplate=utilization-analysis-therapeutic',
  '/utilization-medication':
    '?from=template&selectedTemplate=utilization-analysis-medication',
  '/medication': '?from=template&selectedTemplate=medication-compare-analysis',
  '/physician': '?from=template&selectedTemplate=physician-analysis',
  '/physician/analogy': '?from=template&selectedTemplate=physician-analysis'
};

export const analysisTabManualNames = {
  'utilization-analysis-therapeutic': true,
  'utilization-analysis-medication': true,
  'medication-compare-analysis': true,
  'physician-analysis': true,
  'top-medication-viewall-utilization-therapeutic-search': true,
  'top-physician-viewall-utilization-therapeutic-search': true,
  'top-therapeutic-viewall-utilization-medication-search': true,
  'top-physician-viewall-utilization-medication-search': true
};
