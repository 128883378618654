import PropTypes from 'prop-types';
import React from 'react';

// material-ui

import { Box } from '@mui/material';
import styled from '@emotion/styled';

// project imports
import { drawerWidth } from '../../../store/constant';

// icons
import { useRouter } from 'next/router';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
  AnalysisIcon,
  ChartIcon,
  HamBurgerIcon,
  MedicationIcon,
  PhysicianIcon
} from '../../../icons/icons';
import NavBarTooltip from '../../../components/shared/NavBarTooltip';
import Link from 'next/link';

// style constant
const useStyles = makeStyles()(theme => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: 90,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    boxContainer: {
      display: 'flex',
      padding: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    navIcon: {
      fontSize: theme.spacing(3),
      margin: 'auto',
      fill: 'none'
    },
    activeNavIcon: {
      '& path': {
        stroke: '#397ed0'
      }
    },
    navText: {
      // fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '150%'
    },
    activeNavText: {},
    divider: {
      minWidth: '50%',
      minHeight: '1px'
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      minHeight: theme.mixins.toolbar.minHeight,
      textDecoration: 'none'
    },
    logoLink: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      textDecoration: 'none'
    },
    logoName: {
      color: theme.palette.primary?.main,
      fontWeight: 'bold',
      fontSize: '1.1rem',
      textDecoration: 'none'
    }
  };
});

const NavLink = styled(Link)`
  color: ${props => (props.activelink ? '#fff' : 'unset')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;
//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const getSideBarIcons = props => {
  const sideBarIcons = {
    hamburger: <HamBurgerIcon {...props} />,
    chart: <ChartIcon {...props} />,
    med: <MedicationIcon {...props} />,
    phy: <PhysicianIcon {...props} />,
    analysis: <AnalysisIcon {...props} />
  };

  return sideBarIcons;
};

const Sidebar = ({ open = false, navs }) => {
  const router = useRouter();
  const { classes } = useStyles();
  const theme = useTheme();
  const highLight = router?.query?.customHighlight
    ? `/${router?.query?.customHighlight}`
    : '';

  const getProps = link => {
    const props = { color: '#465671' };
    if (
      highLight === link ||
      (router.pathname && router.pathname.includes(link))
    ) {
      props.color = '#fff';
    }

    return props;
  };

  return (
    // <nav className={classes.drawer} aria-label="mailbox folders">
    <>
      {navs?.map(({ Icon, navText, link, mainLink, visible }) => {
        const newMainLink = highLight===mainLink ? highLight : mainLink;
        return (
          visible && (
            // <Link >
            <NavLink
              underline="none"
              activelink={
                (highLight === mainLink && 'true') ||
                (router.pathname &&
                  router.pathname.includes(newMainLink) &&
                  'true') ||
                undefined
              }
              href={link}
              key={link}
              data-cy={navText}
            >
              <Box
                mt={2}
                display="flex"
                gap="13px"
                flexDirection="row"
                justifyContent={open ? 'start' : 'center'}
                alignItems="center"
                mx={2.9}
                borderRadius={2}
                py={1.5}
                px={1.5}
                style={{
                  height: '39px',
                  backgroundColor:
                    highLight === mainLink
                      ? theme.palette.primary?.dark
                      :!highLight && router.pathname && router.pathname.includes(newMainLink)
                      ? theme.palette.primary?.dark
                      : '#fff'
                }}
              >
                {/* <span style={open == true ? { padding: '5px 0px 0px 10px' } : {}}> */}
                {open ? (
                  getSideBarIcons(getProps(newMainLink))[Icon]
                ) : (
                  <NavBarTooltip info={navText}>
                    {getSideBarIcons(getProps(newMainLink))[Icon]}
                  </NavBarTooltip>
                )}
                {/* </span> */}

                {open && (
                  <div
                    className={classes.navText}
                    fontWeight={
                      router.pathname && router.pathname.includes(newMainLink)
                        ? 700
                        : 400
                    }
                  >
                    {navText}
                  </div>
                )}
              </Box>
            </NavLink>
            // </Link>
          )
        );
      })}
    </>
    // </nav>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
