import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { signOut, useSession } from 'next-auth/react';
// material-ui
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  CssBaseline,
  Toolbar,
  useMediaQuery,
  Grid,
  Box,
  Typography,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon
} from '@mui/material';
import { CloseRounded, Help } from '@mui/icons-material';
import MiniDrawer from './Sidebar/MiniDrawer';

// third-party
import clsx from 'clsx';

// project imports
import Sidebar from './Sidebar';
// import Customization from './../Customization';
import { drawerWidth } from '../../store/constant';
import Link from 'next/link';

// assets
import { useRouter } from 'next/router';
import {
  ArrowBackRounded,
  Logout,
  AdminPanelSettings,
  ArrowDropDown,
  ArrowForward
} from '@mui/icons-material';
import { checkRequiredResponsiveSizeUsingCssMediaQuery } from '../../utils/resposive.utils';
import { getHealthSystem } from '../../queries/overview';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentHealthSystemIdState } from '../../recoils/analysis.recoil.state';
import { useQuery } from 'react-query';
import ScrollDialog from '../../components/policies/Policy';
import Loader from '../../components/Loader';
import Image from 'next/image';
import { userRecoilState } from '../../recoils/common.recoil.state';

// style constant
const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      backgroundColor: '#eff5ff'
    },
    appBar: {
      backgroundColor: theme.palette.background?.default,
      boxShadow: '0px 1px 0px #E0E4ED',
      paddingRight: `${theme.spacing(2)}`
      // backgroundColor: 'green'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    defaultAppBar: {
      marginLeft: 90,
      width: `calc(100% - 90px)`
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px !important',
      paddingLeft: '20px !important',
      width: '100%'
      // paddingTop: theme.spacing(2)
    },
    toolbar_small: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px !important',
      width: '100%'
      // paddingTop: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    logo: {
      maxHeight: theme.spacing(6),
      width: theme.spacing(10)
    },
    search: {
      // minWidth: '550px',
      maxHeight: '40px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '20px',
        maxHeight: '40px'
      },
      '& .MuiInputAdornment-root': {
        maxWidth: '2rem'
      }
    },
    buttonContainer: {
      width: 'auto',
      marginTop: '0.3rem',
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      alignTtems: 'center'
    },
    bellButton: {
      marginRight: '8px'
    },
    badge: {
      top: '-2px'
    },
    logoContainer: {
      width: 90,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2)
    },
    boxContainer: {
      minHeight: theme.mixins.toolbar.minHeight,
      boxSizing: 'border-box',
      flex: 1
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 10,
      overflow: 'hidden'
    },
    breadCrumbs: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 17,
      position: 'absolute'
    },
    title: {
      fontSize: 18,
      fontWeight: '600'
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    mainBreadCrumb: {
      fontWeight: '600',
      color: theme.palette.primary?.main,
      marginRight: 5,
      cursor: 'pointer',
      textDecoration: 'none',
      // fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '120%'
    },
    currentBreadCrumb: {
      // fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '120%'
    },
    tabItem: {
      padding: 0,
      // fontSize: '1rem',
      minWidth: 140,
      cursor: 'pointer'
    },
    main: {
      marginTop: theme.mixins.toolbar.minHeight,
      borderTop: '1px solid #E0E4ED',
      padding: 20
      // width: 'calc(100vw - 80px)'
    },
    backIcon: {
      backgroundColor: theme.palette.primary.dark,
      width: 30,
      height: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 15,
      marginRight: 10,
      cursor: 'pointer'
    }
  };
});


const HeaderNavTypography = styled(Typography)`
  text-decoration: none;
  color: ${props => (props.activelink ? '#fff' : '#387ED0')};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
`;

//-----------------------|| MAIN LAYOUT ||-----------------------//
const AccountMenu = ({ session, smallScreen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const router = useRouter();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setUser = useSetRecoilState(
    userRecoilState
  );

  useEffect(() => {
    if(session?.data?.user){
      setUser(session.data.user)
    }
  }, [session])

  const [user] = useRecoilState(userRecoilState)

  const isAdmin =
    session.data?.user.role === 'GOD' ||
    session.data?.user.role === 'ADMIN' ||
    session.data?.user.role === 'SUPERADMIN';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center'
        }}
        data-cy="account"
      >
        <Tooltip>
          <Box
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              cursor: 'pointer'
            }}
            data-cy="show-account-menu"
          >
            <Image
              alt="profile"
              src="/images/profile.png"
              width={40}
              height={40}
              style={{
                background: '#C4C4C4',
                /* White */
                border: '3px solid #FFFFFF',
                boxShadow: ' 0px 2px 6px rgba(13, 110, 253, 0.16)',
                borderRadius: '50px'
              }}
            />
            {!smallScreen && (
              <Typography sx={{ marginLeft: 1, color: '#000' }}>
                {user?.name || 'Leslie'}
              </Typography>
            )}
            <ArrowDropDown size={20} style={{ color: '#000' }} />
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        data-cy="account-menu"
      >
        <MenuItem onClick={() => router.push('/profile')}>
          <ListItemIcon>
            <Avatar fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={() => router.push('/admin')}>
            <ListItemIcon>
              <AdminPanelSettings fontSize="small" />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        <MenuItem onClick={() => router.push('https://vindecahealthhelp.zendesk.com/hc/en-us')}>
          <ListItemIcon>
            <Help fontSize="small" />
          </ListItemIcon>
          Help
        </MenuItem>
        <MenuItem
          onClick={() =>
            signOut({
              callbackUrl: `/auth/login`
            })
          }
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
const MainLayout = ({
  children,
  navs = {},
  showClose,
  hideClose,
  middleComponent = '',
  healthSystem
}) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
  const [showSidebarForSm, setShowSidebarForSm] = useState(false);
  const { classes } = useStyles();
  const router = useRouter();
  const session = useSession();

  const setHealthSystem = useSetRecoilState(
    currentHealthSystemIdState
  );

  if (healthSystem) {
    setHealthSystem(healthSystem)
  }

  const { isFetching, isLoading, error } = useQuery(
    ['getHealthSystem'],
    () => getHealthSystem(),
    {
      onSuccess: data => {
        if (data?.healthSystemOption?.length) {
          setHealthSystem(data?.healthSystemOption[0]);
        }
      },
      enabled: !healthSystem
    }
  );

  const uptoMdScreenMatched =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:820px)');

  const smallScreen =
    checkRequiredResponsiveSizeUsingCssMediaQuery('(max-width:600px)');

  const handleOpenSidebar = () => {
    setShowSidebarForSm(!showSidebarForSm);
  };

  useEffect(() => {
    if (uptoMdScreenMatched) {
      setShowSidebarForSm(false);
    }
  }, [uptoMdScreenMatched]);

  useEffect(() => {
    const captchaElement = document.querySelector('.grecaptcha-badge');
    if (captchaElement) {
      if (
        router.pathname === '/auth/login' ||
        router.pathname === '/auth/forgot-password'
      ) {
        captchaElement.style.visibility = 'visible';
      } else {
        captchaElement.style.visibility = 'hidden';
      }
    }
  }, [router]);

  const currentPath = navs.tabs?.find(
    item => getUpdatedLinks(item?.link) === router.route
  )?.label;

  function getUpdatedLinks(link) {
    return link?.includes('?') ? link?.split('?')[0] : link;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: showSidebarForSm,
          [classes.defaultAppBar]: !uptoMdScreenMatched && !showSidebarForSm
        })}
        data-cy="header"
      >
        {showClose ? (
          <Grid
            maxWidth
            flex
            alignItems={'center'}
            container
            sx={{ height: 74, paddingLeft: 2 }}
          >
            <Grid xs={6}>
              <div
                onClick={() => hideClose()}
                style={{
                  backgroundColor: '#397ed0',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  width: 30,
                  height: 30,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <ArrowBackRounded style={{ color: '#fff' }} fontSize={'22'} />
              </div>
            </Grid>
            <Grid xs={6} display={'flex'} justifyContent={'flex-end'}>
              <div
                onClick={() => hideClose()}
                style={{
                  backgroundColor: '#397ed0',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  width: 30,
                  height: 30,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <CloseRounded style={{ color: '#fff' }} fontSize="20" />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Toolbar
            className={smallScreen ? classes.toolbar_small : classes.toolbar}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {uptoMdScreenMatched && (
                  <div className={classes.flexRow}>
                    <MiniDrawer />
                  </div>
                )}
                {navs?.breadCrumbs?.length ? (
                  <div className={classes.breadCrumbs} data-cy="header-links">
                    {navs.breadCrumbs.map((itm, index) => (
                      <div key={index} className={classes.flexRow}>
                        {index > 0 ? (
                          <ArrowForward sx={{ fontSize: 16 }} />
                        ) : (
                          <Link
                            href={
                              navs.breadCrumbs[navs.breadCrumbs.length - 2]
                                ?.backPath ||
                              navs.breadCrumbs[navs.breadCrumbs.length - 2]
                                ?.link
                            }
                            data-cy="back-arrow"
                          >
                            <div className={classes.backIcon}>
                              <ArrowBackRounded
                                style={{ color: '#fff' }}
                                fontSize="20"
                              />
                            </div>
                          </Link>
                        )}
                        {itm.link ? (
                          <Link
                            className={classes.mainBreadCrumb}
                            href={itm.link}
                            data-cy={itm.link}
                          >
                            <div>{itm.name}</div>
                          </Link>
                        ) : (
                          <div className={classes.currentBreadCrumb}>
                            {itm.name}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {!uptoMdScreenMatched && (
                      <div className={classes.breadCrumbs}>
                        <Link
                          data-cy={navs.home}
                          className={classes.mainBreadCrumb}
                          href="/overview"
                        >
                          <div>{navs.home}</div>
                        </Link>
                        {currentPath && (
                          <ArrowForward sx={{ fontSize: 14 }} width="16" />
                        )}
                        <div className={classes.currentBreadCrumb}>
                          {currentPath}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div style={smallScreen ? { width: '70%' } : {}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  pt={0}
                  className={classes.boxContainer}
                >
                  <Grid container justifyContent={'center'} overflow="hidden">
                    <div className={classes.tabContainer}>
                      {navs.tabs &&
                        navs.tabs.length > 1 &&
                        navs.tabs.map(({ link, label }) => (
                          <div
                            key={link}
                            className={clsx({
                              [classes.tabItem]: true
                            })}
                          >
                            <Link
                              href={link}
                              component="a"
                              activelink={
                                router.pathname === getUpdatedLinks(link)
                                  ? 'true'
                                  : undefined
                              }
                              style={{
                                display: "flex",
                                textDecoration: "none",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0.5rem",
                                backgroundColor: router.pathname === getUpdatedLinks(link) ? '#397ed0' : '#eff5ff'
                              }}
                              data-cy={label}
                            >
                              <HeaderNavTypography
                                activelink={
                                  router.pathname === getUpdatedLinks(link)
                                    ? 'true'
                                    : undefined
                                }
                              >
                                {label}
                              </HeaderNavTypography>
                            </Link>
                          </div>
                        ))}
                      {navs.title && (
                        <Typography className={classes.title}>
                          {navs.title}
                        </Typography>
                      )}
                      <div className={classes.breadCrumbs}>
                        {middleComponent}
                      </div>
                    </div>
                  </Grid>
                </Box>
              </div>
              <div
                item
                lg={3}
                sm={4}
                md={3}
                xs={2}
                className={classes.buttonContainer}
              >
                <AccountMenu
                  session={session}
                  smallScreen={uptoMdScreenMatched}
                />
              </div>
            </div>
          </Toolbar>
        )}
      </AppBar>

      {/* drawer */}

      {!uptoMdScreenMatched && (
        <Sidebar
          handleOpenSidebar={handleOpenSidebar}
          open={showSidebarForSm}
        />
      )}

      {/* main content */}
      <main
        className={classes.main}
        style={{
          minWidth: `${showSidebarForSm ? 'calc(100vw - 255px)' : 'calc(100vw - 105px)'
            }`,
          minHeight: '90vh',
          padding: smallScreen ? 4 : 20,
          paddingRight: smallScreen ? 4 : 8
        }}
      >
        {isFetching && <Loader />}
        <div>{children}</div>
      </main>

      <ScrollDialog />
      {/* <Customization /> */}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
