import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import {
  Drawer,
  Typography,
  Link as MatLink,
  IconButton,
  Paper
} from '@mui/material';

// project imports
import {
  drawerWidth,
  showAnalysis,
  showMedication,
  showPhysician,
  showUtilization
} from './../../../store/constant';
import SideBarContent from './Sidebar';

// icons
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from 'tss-react/mui';
import {
  historyRecoilState
} from '../../../recoils/common.recoil.state';
import { useRecoilState } from 'recoil';
import Image from 'next/image';

// style constant
const useStyles = makeStyles()(theme => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: 90,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    boxContainer: {
      display: 'flex',
      padding: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    navIcon: {
      fontSize: theme.spacing(3),
      margin: 'auto',
      fill: 'none'
    },
    activeNavIcon: {
      '& path': {
        stroke: '#397ed0'
      }
    },
    navText: {
      fontSize: '1rem',
      marginLeft: 10
    },
    divider: {
      minWidth: '50%',
      minHeight: '1px'
    },
    logoLink: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      textDecoration: 'none'
    },
    logoName: {
      color: theme.palette.primary?.main,
      fontWeight: 'bold',
      fontSize: '1.1rem',
      textDecoration: 'none'
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: theme.mixins.toolbar.minHeight,
      textDecoration: 'none',
      borderBottom: '1px solid #EDF2F8'
    }
  };
});

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

const Sidebar = ({ showSidebar = false, open = false, handleOpenSidebar }) => {
  const [historyState, setHistoryState] = useRecoilState(historyRecoilState);
  const router = useRouter();
  const { classes } = useStyles();

  const navs = useMemo(
    () => [
      {
        Icon: 'hamburger',
        navText: 'Overview',
        link: '/overview',
        mainLink: '/overview',
        visible: true
      },
      {
        Icon: 'chart',
        navText: 'Utilization',
        mainLink: '/utilization',
        link: `${
          historyState?.utilization ? historyState?.utilization : '/utilization'
        }`,
        visible: showUtilization
      },
      {
        Icon: 'med',
        navText: 'Medication Strategy',
        mainLink: '/medication',
        link: `${
          historyState?.medication ? historyState?.medication : '/medication'
        }`,
        visible: showMedication
      },
      {
        Icon: 'phy',
        navText: 'Physician',
        mainLink: '/physician',
        link: `${
          historyState?.physician ? historyState?.physician : '/physician'
        }`,
        visible: showPhysician
      },
      {
        Icon: 'analysis',
        navText: 'Analytics',
        link: '/analysis',
        mainLink: '/analysis',
        visible: showAnalysis
      }
    ],
    [historyState]
  );
  const updateHistoryState = (key, path) => {
    setHistoryState(prevHistoryState => ({
      ...prevHistoryState,
      [key]: path
    }));
  };
  useEffect(() => {
    const pathToKeyMap = {
      '/utilization': 'utilization',
      '/utilization/compare': 'utilization',
      '/physician': 'physician',
      '/physician/analogy': 'physician',
      '/medication': 'medication'
    };

    const currentPath = router?.pathname;
    const keyToUpdate = pathToKeyMap[currentPath];

    if (keyToUpdate && router?.asPath) {
      updateHistoryState(keyToUpdate, router.asPath);
    }
  }, [router?.pathname, router?.asPath]);

  return (
    <Drawer
      component={Paper}
      variant={'permanent'}
      anchor="left"
      // open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      // ModalProps={{ keepMounted: true }}
      color="inherit"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <div className={classes.logoContainer}>
        <MatLink data-cy="home" href="/overview" className={classes.logoLink}>
          <Image
            src={'/images/logo.png'}
            alt="Vindeca Health Logo"
            width={35}
            height={35}
          />
          {open && (
            <Typography className={classes.logoName}>Vindeca Health</Typography>
          )}
        </MatLink>
        <IconButton onClick={handleOpenSidebar}>
          <FontAwesomeIcon
            icon={faBars}
            fixedWidth
            width={18}
            style={{ color: '#000' }}
          />
        </IconButton>
      </div>

      <SideBarContent
        showSidebar={showSidebar}
        open={open}
        handleOpenSidebar={handleOpenSidebar}
        navs={navs}
      />
    </Drawer>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
