import React from 'react';

const TermsOfService = () => {
  const paragraphStyle = {
    marginTop: 'calc(17.5px + 0.78125vw)',
    lineHeight: 1.6,
    fontSize: '1.1rem',
    textAlign: 'justify'
  };

  const subHeadingStyle = {
    marginTop: 'calc(17.5px + 0.78125vw)',
    color: '#2e2e2e',
    fontSize: '1.4rem',
    fontWeight: 'bolder',
    letterSpacing: '-.9px',
    lineHeight: '1.1',
    textAlign: 'justify'
  };
  return (
    <main
      style={{
        // margin: '5vw auto',
        // padding: '0px 20%',
        color: '#595d6b',
        fontWeight: 400,
        letterSpacing: '-.25px',
        fontSize: '1rem',
        lineHeight: '2',
        fontFamily:
          'Graphik,Calibre,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif'
      }}
    >
      

      <p style={paragraphStyle}>
        {' '}
        These Terms of Service govern your use of the website located at{' '}
        <a href="https://vindecahealth.com">https://vindecahealth.com</a> and
        any related services provided by Vindeca Health.
      </p>

      <p style={paragraphStyle}>
        {' '}
        By accessing{' '}
        <a href="https://vindecahealth.com">https://vindecahealth.com</a>, you
        agree to abide by these Terms of Service and to comply with all
        applicable laws and regulations. If you do not agree with these Terms of
        Service, you are prohibited from using or accessing this website or
        using any other services provided by Vindeca Health.
      </p>

      <p style={paragraphStyle}>
        {' '}
        We, Vindeca Health, reserve the right to review and amend any of these
        Terms of Service at our sole discretion. Upon doing so, we will update
        this page. Any changes to these Terms of Service will take effect
        immediately from the date of publication.
      </p>

      <p style={paragraphStyle}>
        {' '}
        These Terms of Service were last updated on 1 September 2023.
      </p>

      <h2 style={subHeadingStyle}> Limitations of Use</h2>
      <p style={paragraphStyle}>
        {' '}
        By using this website, you warrant on behalf of yourself, your users,
        and other parties you represent that you will not:
      </p>
      <ul>
        <li>
          {' '}
          modify, copy, prepare derivative works of, decompile, or reverse
          engineer any materials and software contained on this website;
        </li>
        <li>
          {' '}
          remove any copyright or other proprietary notations from any materials
          and software on this website;
        </li>
        <li>
          {' '}
          transfer the materials to another person or "mirror" the materials on
          any other server;
        </li>
        <li>
          {' '}
          knowingly or negligently use this website or any of its associated
          services in a way that abuses or disrupts our networks or any other
          service Vindeca Health provides;
        </li>
        <li>
          {' '}
          use this website or its associated services to transmit or publish any
          harassing, indecent, obscene, fraudulent, or unlawful material;
        </li>
        <li>
          {' '}
          use this website or its associated services in violation of any
          applicable laws or regulations;
        </li>
        <li>
          {' '}
          use this website in conjunction with sending unauthorized advertising
          or spam;
        </li>
        <li>
          {' '}
          harvest, collect, or gather user data without the user’s consent; or
        </li>
        <li>
          {' '}
          use this website or its associated services in such a way that may
          infringe the privacy, intellectual property rights, or other rights of
          third parties.
        </li>
      </ul>

      <h2 style={subHeadingStyle}> Intellectual Property</h2>

      <p style={paragraphStyle}>
        {' '}
        The intellectual property in the materials contained in this website are
        owned by or licensed to Vindeca Health and are protected by applicable
        copyright and trademark law. We grant our users permission to download
        one copy of the materials for personal, non-commercial transitory use.
      </p>

      <p style={paragraphStyle}>
        {' '}
        This constitutes the grant of a license, not a transfer of title. This
        license shall automatically terminate if you violate any of these
        restrictions or the Terms of Service, and may be terminated by Vindeca
        Health at any time.
      </p>

      <h2 style={subHeadingStyle}> Liability</h2>

      <p style={paragraphStyle}>
        {' '}
        Our website and the materials on our website are provided on an 'as is'
        basis. To the extent permitted by law, Vindeca Health makes no
        warranties, expressed or implied, and hereby disclaims and negates all
        other warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property, or other violation of rights.
      </p>
      <p style={paragraphStyle}>
        {' '}
        In no event shall Vindeca Health or its suppliers be liable for any
        consequential loss suffered or incurred by you or any third party
        arising from the use or inability to use this website or the materials
        on this website, even if Vindeca Health or an authorized representative
        has been notified, orally or in writing, of the possibility of such
        damage.
      </p>

      <p style={paragraphStyle}>
        {' '}
        In the context of this agreement, "consequential loss" includes any
        consequential loss, indirect loss, real or anticipated loss of profit,
        loss of benefit, loss of revenue, loss of business, loss of goodwill,
        loss of opportunity, loss of savings, loss of reputation, loss of use
        and/or loss or corruption of data, whether under statute, contract,
        equity, tort (including negligence), indemnity or otherwise.
      </p>

      <p style={paragraphStyle}>
        {' '}
        Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
      </p>

      <h2 style={subHeadingStyle}> Accuracy of Materials</h2>
      <p style={paragraphStyle}>
        {' '}
        The materials appearing on our website are not comprehensive and are for
        general information purposes only. Vindeca Health does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on this website, or otherwise
        relating to such materials or on any resources linked to this website.
      </p>
      <h2 style={subHeadingStyle}> Links</h2>
      <p style={paragraphStyle}>
        {' '}
        Vindeca Health has not reviewed all of the sites linked to its website
        and is not responsible for the contents of any such linked site. The
        inclusion of any link does not imply endorsement, approval or control by
        Vindeca Health of the site. Use of any such linked site is at your own
        risk and we strongly advise you make your own investigations with
        respect to the suitability of those sites.
      </p>
      <h2 style={subHeadingStyle}> Right to Terminate</h2>
      <p style={paragraphStyle}>
        {' '}
        We may suspend or terminate your right to use our website and terminate
        these Terms of Service immediately upon written notice to you for any
        breach of these Terms of Service.
      </p>
      <h2 style={subHeadingStyle}> Severance</h2>
      <p style={paragraphStyle}>
        {' '}
        Any term of these Terms of Service which is wholly or partially void or
        unenforceable is severed to the extent that it is void or unenforceable.
        The validity of the remainder of these Terms of Service is not affected.
      </p>
      <h2 style={subHeadingStyle}> Governing Law</h2>
      <p style={paragraphStyle}>
        {' '}
        These Terms of Service are governed by and construed in accordance with
        the laws of USA. You irrevocably submit to the exclusive jurisdiction of
        the courts in that State or location.
      </p>
    </main>
  );
};

export default TermsOfService;
